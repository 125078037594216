import compact from 'lodash/compact';
import get from 'lodash/get';
import json2csv from 'json2csv';
import dayjs from 'dayjs';

import { fmt } from 'IntlWrapper/IntlWrapper';
import { phoneRegExpEgyptWithZero, phoneRegExpSaudi } from 'constants/form';
import { COUNTRIES } from 'constants/country-data';
import { phoneNumberRule } from 'utils/forms';

export const badgesColors = {
  'In progress': {
    color: '#E6C72D',
    backgroundColor: 'rgba(230, 199, 45, 16%)'
  },
  'Picked up': { color: '#fff', backgroundColor: '#0400FF' },
  Terminated: { color: 'black', backgroundColor: '#FFFB00' },
  'Picking up': { color: 'black', backgroundColor: '#FFFB00' },
  'Pickup requested': { color: '#000', backgroundColor: '#CBCBCA' },
  'Waiting for route': { color: '#000', backgroundColor: '#CBCBCA' },
  'Received at warehouse': {
    color: '#fff',
    backgroundColor: '#0400FF'
  },
  'Picking up from warehouse': { color: '#000', backgroundColor: '#FFFB00' },
  'Arrived at customer': { color: '#fff', backgroundColor: '#0400FF' },
  'Arrived at warehouse': { color: '#fff', backgroundColor: '#0400FF' },
  'Waiting for Pickup': { color: '#000', backgroundColor: '#FFFB00' },
  Delivered: { color: '#000', backgroundColor: '#04FF00' },
  Canceled: { color: '#fff', backgroundColor: '#FF3333' },
  Exception: { color: '#fff', backgroundColor: '#FF3333' },
  Lost: { color: '#fff', backgroundColor: '#FF3333' },
  Damaged: { color: '#fff', backgroundColor: '#FF3333' },
  Investigation: { color: '#fff', backgroundColor: '#FF7F1A' },
  'In transit between Hubs': { color: '#000', backgroundColor: '#FDE541' },
  defualt: { color: '#0080ff', backgroundColor: 'rgba(0, 128, 255, 16%)' },
  Requested: { color: '#E6C72D', backgroundColor: 'rgba(230, 199, 45, 16%)' },
  'On Hold': { color: '#E6C72D', backgroundColor: 'rgba(230, 199, 45, 16%)' },
  pending: { color: '#E6C72D', backgroundColor: 'rgba(230, 199, 45, 16%)' },
  Processing: { color: '#26245C', backgroundColor: 'rgba(38, 36, 92, 16%)' },
  'Delivery on route': {
    color: '#26245C',
    backgroundColor: 'rgba(38, 36, 92, 16%)'
  },
  Returned: { color: '#FF9100', backgroundColor: 'rgba(255, 145, 0, 16%)' },
  'Returned To Business': {
    color: '#FF9100',
    backgroundColor: 'rgba(255, 145, 0, 16%)'
  },

  Accepted: {
    color: '#3ACC6C',
    backgroundColor: 'rgba(58, 204, 108, 16%)'
  },
  Done: {
    color: '#3ACC6C',
    backgroundColor: 'rgba(58, 204, 108, 16%)'
  },

  'Route Assigned': {
    color: '#000',
    backgroundColor: '#FFA500'
  },
  Failed: { color: '#D7262C', backgroundColor: 'rgba(215, 38, 44, 16%)' },
  'Awaiting for Action': {
    color: '#D7262C',
    backgroundColor: 'rgba(215, 38, 44, 16%)'
  },
  Created: {
    color: 'rgb(0, 128, 255)',
    backgroundColor: 'rgba(0, 128, 255, 16%)'
  }
};

export const filterColors = {
  'Awaiting for Action': '#D7262C',
  'On Hold': '#E6C72D',
  Processing: '#26245C',
  Created: '#0080FF',
  Returned: '#FF9100',
  Delivered: '#3ACC6C',
  Requested: '#E6C72D',
  Pickedup: '#0080FF',
  Canceled: '#D7262C',
  'Pickup requested': '#0080FF',
  Terminated: 'red'
};

export const standardStyles = {
  done: { color: '#3ACC6C', backgroundColor: 'rgba(58, 204, 108, 16%)' }
};

export const shipmentsColumns = [
  {
    dataField: 'shipment_id',
    text: 'Shipment ID'
  },
  {
    dataField: 'date',
    text: 'Date'
  },
  {
    dataField: 'customer_info',
    text: 'Customer Info'
  },
  {
    dataField: 'location',
    text: 'Location'
  },
  {
    dataField: 'cod',
    text: 'COD'
  },
  {
    dataField: 'type',
    text: 'Type'
  },
  {
    dataField: 'status',
    text: 'Status'
  }
];

export const overviewColumns = [
  {
    dataField: 'trackingNumber',
    text: 'Tracking Number'
  },
  {
    dataField: 'customer_info',
    text: 'Customer Info'
  },
  {
    dataField: 'location',
    text: 'Location'
  },
  {
    dataField: 'reason',
    text: 'Reason'
  },
  {
    dataField: 'held_for',
    text: 'Held For'
  }
];

export const shipmentsStatusDict = {
  'Awaiting for Action': ['Failed', 'Exception'],
  'On Hold': ['Canceled'],
  Terminated: ['Terminated'],
  Processing: [
    'Scheduled',
    'Route Assigned',
    'Assigned to pickup',
    'Waiting for Pickup',
    'Picking up',
    'Picking up from warehouse',
    'Arrived at business',
    'Receiving',
    'Arrived at warehouse',
    'Arrived At Warehouse',
    'Received at warehouse',
    'Receive failed',
    'Picked up',
    'Delivering',
    'En route to warehouse',
    'Delivery on route',
    'Arrived at customer',
    'Pickup failed',
    'In progress',
    'In transit between Hubs',
    'Picked up from business'
  ],
  Created: ['Pickup requested', 'pending', 'Waiting for route'],
  Delivered: ['Delivered'],
  Returned: ['Returned', 'Returned to business']
};

export const pickupsStatusDict = {
  Requested: ['Requested'],
  Processing: [
    'Route Assigned',
    'Accepted',
    'Picking up',
    'Arrived at business',
    'Receiving'
  ],
  'Picked up': [
    'En route to warehouse',
    'Arrived at warehouse',
    'Arrived At Warehouse',
    'Done',
    'Picked up'
  ],
  Canceled: ['Canceled', 'Failed']
};

export const mapStateToStatus = (state, dict) => {
  const dictionary =
    dict === 'shipments' ? shipmentsStatusDict : pickupsStatusDict;
  return Object.keys(dictionary).find((key) => dictionary[key].includes(state));
};

export const mapTimeSlots = {
  '13:00 to 16:00': '01:00PM to 04:00PM',
  '10:00 to 13:00': '10:00AM to 01:00PM'
};

export const phoneRegExp =
  JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
  COUNTRIES[1].codeName
    ? phoneRegExpSaudi
    : phoneRegExpEgyptWithZero;

export const CSVtoArray = (text) => {
  const reValid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
  const reValue =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
  if (!reValid.test(text)) return null;
  const a = [];
  // eslint-disable-next-line func-names
  text.replace(reValue, function (m0, m1, m2, m3) {
    if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
    else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
    else if (m3 !== undefined) a.push(m3);
    return '';
  });
  if (/,\s*$/.test(text)) a.push('');
  return a;
};

export const downloadAsCSV = (text, fileName) => {
  const pom = document.createElement('a');
  const csvContent = text; // here we load our csv data
  const blob = new Blob([csvContent], {
    type: 'text/csv;charset=utf-8;'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
};

const _formatHeader = (csv, headerMapping) => {
  const lines = csv.split(/\r\n|\n/);

  const headers = lines[0].split(',');
  let formattedHeader = '';

  headers.forEach((headerElement, index) => {
    // eslint-disable-next-line no-useless-escape
    let newHeader = headerElement.replace(/\"/gi, '');

    if (headerMapping[headerElement] !== undefined) {
      newHeader = headerMapping[headerElement];
    }

    formattedHeader += newHeader;

    if (index !== headers.length - 1) formattedHeader += ',';
  });

  return csv.replace(headers, formattedHeader);
};

const _formatFileName = (fileName, extension) => {
  const date = new Date();
  const formattedDate = `${date.getDate()}_${
    date.getMonth() + 1
  }_${date.getFullYear()}`;

  return `${fileName}_${formattedDate}.${extension}`;
};

export const createCSV = (data, headerMappings, fileName) => {
  const csv = json2csv.parse(data, Object.keys(headerMappings));

  const formattedCsv = _formatHeader(csv, headerMappings);

  const blob = new Blob([formattedCsv], { type: 'text/csv;charset=utf-8' });
  const formatFileName = _formatFileName(fileName, 'csv');
  const pom = document.createElement('a');
  pom.href = URL.createObjectURL(blob);
  pom.setAttribute('download', formatFileName);
  pom.click();
};

export const ticketReasons = {
  pickup: [
    'Pickup delay',
    "Star didn't show up",
    'Pickup confirmation',
    'Request a car',
    'Change pickup date/time',
    'Change Pickup Location',
    'Confirm Picking Specific Order',
    'Pickup Rescheduled',
    'Star has no receipt',
    'Star behavior',
    'Need confirmation to number of packages',
    'Lost pickup',
    'Star declined to pickup the orders (Packing Issue)',
    'Other'
  ],
  shipment: [
    'Delayed Order',
    'Re-Request Orders',
    'Update COD amount',
    'Update Customer Information',
    'Cancel/Return Order',
    'Fake Update',
    'Reschedule Order',
    'Order Status Inquiry',
    'Push Delivery',
    'Dispatch Order',
    'Delayed Return',
    'Orders Upload on the system'
  ],
  transaction: [
    'COD delays',
    'COD reports',
    'Updating / submitting bank details',
    'Wrong transfer amount',
    'ACH Request',
    'I request a compensation for transfer delay',
    'Other'
  ],
  general: [
    'I need packing flyers',
    'Packing material is delayed',
    'Request better rate',
    'Other'
  ]
};

export const ticketRank = ['high', 'medium', 'low'];

export function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild.textContent === 'N/A' ? '' : div.firstChild.textContent;
}

export const logout = () => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  window.location.reload();
};

export const handlingErrorMessage = (err, defaultMsg, stopLoading) => {
  // error
};

export const moneyFormatter = (val) =>
  new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'egp',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(val);

export const showBill = (data) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], {
    type: 'application/pdf;base64'
  });
  const fileURL = URL.createObjectURL(file);

  const newWindow = window.open('/_loading');

  if (newWindow) {
    newWindow.onload = () => {
      newWindow.location = fileURL;
    };
  }
};

export const printShipmentAirwaybill = async (shipment, printAirwaybill) => {
  try {
    const a = await printAirwaybill(shipment._id);
    if (a) {
      showBill(a.payload.data);
    }
  } catch (err) {
    return err;
  }
  return false;
};

export const MAX_WIDE_SCREEN = 990;

/**
 * format options to be bound in select controls
 * @param keyFieldName
 * @param labelFieldName
 * @param data
 * @returns {*}
 */
export const formatOptions = (keyFieldName, labelFieldName, data) => {
  if (keyFieldName && labelFieldName) {
    const labelFields = labelFieldName.split(',');
    return data.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${get(item, value, '')}`;
      }, '');

      return { value: item[keyFieldName], label: labelValue, ...item };
    });
  }
  return data.map((item) => ({ value: item, label: item }));
};

/**
 * download excel file
 * @param text
 * @param fileName
 */
export const downloadAsXlsx = (text, fileName) => {
  const pom = document.createElement('a');
  const xlsxContent = text; // here we load our csv data

  const blob = new Blob([xlsxContent], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;

  pom.setAttribute('download', fileName);
  pom.click();
};

export const headerDownload = {
  responseType: 'arraybuffer',
  Accept:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json'
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const getQueryParam = (key) => {
  return new URLSearchParams(window.location.search.substring(1)).get(key);
};

export const formatAddress = (address = {}) => {
  const {
    buildingNumber,
    firstLine,
    secondLine,
    floor,
    apartment,
    district,
    zone,
    city
  } = address;

  const addressArray = compact([
    buildingNumber,
    firstLine,
    secondLine,
    floor &&
      `${fmt({
        id: `location_form_labels.floor`
      })}: ${floor}`,
    apartment &&
      `${fmt({
        id: `location_form_labels.apartment`
      })}: ${apartment}`,
    get(district, 'name'),
    get(zone, 'name'),
    get(city, 'name')
  ]);

  const formattedAddress = addressArray.join(', ');

  return formattedAddress;
};

export const emailRegex = /^\S+@\S+\.\S+$/;

export const getInitials = (fullName) => {
  return fullName
    .replace(/[^a-zA-Z- ]/g, '')
    .match(/\b\w/g)
    .join('')
    .toUpperCase();
};

export const validatePhoneNumber = async ({
  value,
  required,
  phoneNumberRuleProps
}) => {
  if (required && !value) {
    return false;
  }

  try {
    const data = await phoneNumberRule({ ...phoneNumberRuleProps }).validator(
      undefined,
      value
    );

    if (data) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const calculateAging = (date) => {
  const currentDate = dayjs();
  const actionDate = dayjs(date);

  const daysDiff = currentDate.diff(actionDate, 'day');

  return daysDiff;
};

export const shortenString = (str = '', maxLength) => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const getTabsPaths = (tabs) => {
  const path = [];
  Object.keys(tabs).filter((tab) => {
    return tabs[tab].isUserAuthorized && path.push(tabs[tab].path);
  });

  return path;
};
