import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getProductList = (payload) => {
  return http.post(`/fulfillment/products/list`, payload);
};

export const createFulfillmentMassOrders = (payload) => {
  return http.post(`/fulfillment/deliveries/bulky`, payload);
};

export const exportCSVInventory = (payload) => {
  return http.post(`/fulfillment/products/export`, payload, downloadHeader);
};

export const getProductDetails = (id) => {
  return http.get(`/fulfillment/products?bostaSku=${id}`);
};

export const getBusinesses = () => {
  return http.get(`/businesses/valid-fulfillment-activated-names`);
};

export const getValidFulfillmentBusinesses = (payload) => {
  return http.get(`/businesses/valid-new-fulfillment-activated-names`, payload);
};

export const getPurchaseList = (payload) => {
  return http.post(`/fulfillment/purchase/list`, payload);
};

export const getPurchaseDetails = ({ id }, payload) => {
  return http.post(`fulfillment/purchase/order-line/${id}`, payload);
};

export const exportInventoryRequests = (payload) => {
  return http.post(`fulfillment/purchase/export`, payload, downloadHeader);
};

// Purchase Orders (PO)
export const createPO = (payload) => {
  return http.post(`/fulfillment-v2/purchase-orders`, payload);
};

export const validatePOBeforeCreation = (payload) => {
  return http.post(
    `/fulfillment-v2/purchase-orders/validate-creation`,
    payload
  );
};

export const getPO = (poId) => {
  return http.get(`/fulfillment-v2/purchase-orders/${poId}`);
};

export const changePOState = (poId, payload) => {
  return http.post(`/fulfillment-v2/purchase-orders/${poId}/state`, payload);
};

export const getPOSerialsStatus = (poId) => {
  return http.get(`/fulfillment-v2/purchase-orders/${poId}/serials-status`);
};

export const getPOLineSerialNumbers = (lineId) => {
  return http.get(`/fulfillment-v2/purchase-orders/lines/${lineId}/serials`);
};

export const getSerialsToPrint = (payload) => {
  return http.get(`/fulfillment-v2/serials/print-serials`, payload);
};

export const getSerials = (payload) => {
  return http.get(`/fulfillment-v2/serials`, payload);
};

export const getPOs = (payload) => {
  return http.get(`/fulfillment-v2/purchase-orders`, payload);
};

export const getPOStatistics = () => {
  return http.get(`/fulfillment-v2/purchase-orders/statistics`);
};

export const exportPOs = (payload) => {
  return http.post(
    `/fulfillment-v2/purchase-orders/export`,
    payload,
    downloadHeader
  );
};

export const editPO = (poId, payload) => {
  return http.put(`/fulfillment-v2/purchase-orders/${poId}`, payload);
};

// Businesses APIs
export const getFulfillmentBusinesses = (payload) => {
  return http.get(`/fulfillment-v2/business`, payload);
};

export const exportFulfillmentBusinesses = (payload) => {
  return http.post(`/fulfillment-v2/business/export`, payload, downloadHeader);
};

export const getFulfillmentBusinessDetails = (businessId) => {
  return http.get(`/fulfillment-v2/business/${businessId}`);
};

export const getFulfillmentUsers = () => {
  return http.get(`/users/fulfillment-users`);
};
