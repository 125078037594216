import { useContext, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import {
  HUBS_OVERVIEW_TABLE_COLUMNS,
  OFD_EXPORT_DATE_FORMAT,
  SINGLE_CARDS_INFOS
} from 'constants/hub-overview';
import { OpsControl } from 'contexts/ops-control.context';
import {
  PENDING_TRANSIT_DATE_FORMAT,
  TOGGLE_BUTTON_INITAL_VALUE,
  TOGGLE_BUTTONS_LIST
} from 'constants/pending-transit';
import { CURRENT_DATE_PARAMS } from 'constants/sorting-facility';
import {
  exportDispatchingAllHubsDeliveries,
  exportDispatchingOpsRoom,
  getCurrentActiveDate,
  getDispatchingOpsRoom
} from 'services/ops-control';
import { ofdDeliveriesExport } from 'services/hubs';
import { downloadAsXlsx } from 'utils/download';

import BRToggleButton from 'components/BRToggleButton/BRToggleButton';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import PendingTransitFilter from '../PendingTransit/PendingTransitFilter/PendingTransitFilter';
import SingleInfoCard from '../SingleCardInfo/SingleCardInfo';
import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import HubView from './HubView/HubView';

import { ReactComponent as HubsIcon } from 'assets/bosta-icons/hubs.svg';

import './HubsOverview.less';

const HubsOverview = ({ intl }) => {
  const { allHubs } = useContext(OpsControl);
  const [loading, setLoading] = useState(false);
  const [currentHub, setCurrentHub] = useState();
  const [tableData, setTableData] = useState([]);
  const [initalDate, setInitalDate] = useState();
  const [toggleButtonValue, setToggleButtonValue] = useState(
    TOGGLE_BUTTON_INITAL_VALUE
  );
  const [selectedDate, setSelectedDate] = useState();
  const [HubDetails, setHubDetails] = useState();
  const [cardData, setCardData] = useState({
    toBeDispatched: 0,
    pending: 0,
    addedToRoute: 0,
    dispatched: 0,
    late: 0,
    missed: 0,
    attempts: 0,
    exceeded: 0
  });

  useEffect(() => {
    getCurrentinitalDate();
  }, []);

  function getCounts(el, toggleButton) {
    const counts = {
      toBeDispatched: 0,
      pending: 0,
      addedToRoute: 0,
      dispatched: 0,
      late: 0,
      missed: 0,
      attempts: 0,
      exceeded: 0
    };

    switch (toggleButton) {
      case TOGGLE_BUTTONS_LIST[1].value:
        counts.toBeDispatched = el?.to_be_dispatched_forward_count;
        counts.pending = el?.pending_not_received_forward_count;
        counts.addedToRoute = el?.added_to_route_forward_count;
        counts.dispatched = el?.dispatched_forward_count;
        counts.late = el?.late_forward_count;
        counts.missed = el?.missed_forward_count;
        counts.attempts = el?.zero_attempts_forward_count;
        counts.exceeded = el?.exceeded_sla_forward_count;
        break;

      case TOGGLE_BUTTONS_LIST[2].value:
        counts.toBeDispatched = el?.to_be_dispatched_return_count;
        counts.pending = el?.pending_not_received_return_count;
        counts.addedToRoute = el?.added_to_route_return_count;
        counts.dispatched = el?.dispatched_return_count;
        counts.late = el?.late_return_count;
        counts.missed = el?.missed_return_count;
        counts.attempts = el?.zero_attempts_return_count;
        counts.exceeded = el?.exceeded_sla_return_count;
        break;

      default:
        counts.toBeDispatched =
          el?.to_be_dispatched_forward_count +
          el?.to_be_dispatched_return_count;
        counts.pending =
          el?.pending_not_received_forward_count +
          el?.pending_not_received_return_count;
        counts.addedToRoute =
          el?.added_to_route_forward_count + el?.added_to_route_return_count;
        counts.dispatched =
          el?.dispatched_forward_count + el?.dispatched_return_count;
        counts.late = el?.late_forward_count + el?.late_return_count;
        counts.missed = el?.missed_forward_count + el?.missed_return_count;
        counts.attempts =
          el?.zero_attempts_forward_count + el?.zero_attempts_return_count;
        counts.exceeded =
          el?.exceeded_sla_forward_count + el?.exceeded_sla_return_count;
        break;
    }

    return counts;
  }

  const formatTableData = (data, toggleButton) => {
    let toBeDispatchedSum = 0,
      pendingSum = 0,
      addedToRouteSum = 0,
      dispatchedSum = 0,
      lateSum = 0,
      missedSum = 0,
      attemptsSum = 0,
      exceededSum = 0;
    const formattedData = data?.map((el) => {
      const {
        toBeDispatched,
        dispatched,
        late,
        missed,
        addedToRoute,
        pending,
        attempts,
        exceeded
      } = getCounts(el, toggleButton);

      toBeDispatchedSum += toBeDispatched;
      pendingSum += pending;
      addedToRouteSum += addedToRoute;
      dispatchedSum += dispatched;
      lateSum += late;
      missedSum += missed;
      attemptsSum += attempts;
      exceededSum += exceeded;
      return {
        hub: (
          <div
            onClick={() => {
              setHubDetails(el);
            }}
            className="hub-deliveries__hubname-column"
          >
            {el?.warehouse_name}
          </div>
        ),
        to_be_dispatched: toBeDispatched,
        dispatched: dispatched,
        late: late,
        missed: missed
      };
    });

    setCardData({
      toBeDispatched: toBeDispatchedSum,
      pending: pendingSum,
      addedToRoute: addedToRouteSum,
      dispatched: dispatchedSum,
      late: lateSum,
      missed: missedSum,
      attempts: attemptsSum,
      exceeded: exceededSum
    });
    return formattedData;
  };

  const exportCardsData = async ({
    deliveryReceivedState,
    zeroAttemptsOrders,
    exceededSlaOrders,
    exportApi,
    hubId,
    selectedDate
  }) => {
    try {
      const payload = {
        dispatchingState: deliveryReceivedState,
        ...(exportApi === exportDispatchingAllHubsDeliveries && { hubId }),
        zeroAttemptsOrders,
        exceededSlaOrders,
        date: selectedDate.format(PENDING_TRANSIT_DATE_FORMAT),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[1].value && {
          isForwardDelivery: true
        }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[2].value && {
          isForwardDelivery: false
        })
      };
      const res = await exportApi(payload, hubId);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const getCurrentinitalDate = async () => {
    const payload = { pageName: CURRENT_DATE_PARAMS.HUB_DISPATCHING };
    try {
      const res = await getCurrentActiveDate(payload);
      setInitalDate(dayjs(res?.data?.currentDate));
      setSelectedDate(dayjs(res?.data?.currentDate));
      fetchTableData(
        dayjs(res?.data?.currentDate),
        currentHub,
        toggleButtonValue
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchTableData = async (
    selectedDateValue = null,
    currentHubValue = null,
    toggleButton = null
  ) => {
    try {
      setLoading(true);
      const payload = {
        date:
          selectedDateValue !== null && (selectedDateValue || selectedDate)
            ? selectedDateValue?.format(PENDING_TRANSIT_DATE_FORMAT) ||
              selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
            : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(currentHubValue !== null &&
          (currentHubValue || currentHub) && {
            hubId: currentHubValue || currentHub
          })
      };
      const res = await getDispatchingOpsRoom(payload);
      const formattedData = formatTableData(
        res?.data,
        toggleButton || toggleButtonValue,
        currentHubValue || currentHub
      );
      setTableData(formattedData);
      setLoading(false);
    } catch (error) {
      notify(error.message);
    }
  };

  const exportTableData = async () => {
    try {
      const payload = {
        date: selectedDate
          ? selectedDate.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(currentHub && { hubId: currentHub }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[1].value && {
          forwardDeliveriesOnly: true
        }),
        ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[2].value && {
          forwardDeliveriesOnly: false
        })
      };

      const data = await exportDispatchingOpsRoom(payload);
      downloadAsXlsx(
        data,
        `dispatching_report_${selectedDate || dayjs()}.xlsx`
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const exportDispatchingCard = async () => {
    const startOfDayUTC = initalDate.format(OFD_EXPORT_DATE_FORMAT);
    const endOfDayUTC = initalDate.endOf('day').format(OFD_EXPORT_DATE_FORMAT);
    const payload = {
      ...(toggleButtonValue !== TOGGLE_BUTTONS_LIST[0].value && {
        type: toggleButtonValue.toLowerCase()
      }),
      ofdAtStart: startOfDayUTC,
      ofdAtEnd: endOfDayUTC,
      hubId: currentHub
    };
    try {
      await ofdDeliveriesExport(payload);
      notify(
        intl.formatMessage({
          id: 'monitor_hub_operations.common.export_successful'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const changeToggleButton = (value) => {
    setToggleButtonValue(value);
    fetchTableData(selectedDate, currentHub, value);
  };

  const handleHubChange = (hubValue) => {
    setCurrentHub(hubValue);
    fetchTableData(selectedDate, hubValue, toggleButtonValue);
  };

  const changeDateValue = (dateValue) => {
    setSelectedDate(dateValue);
    fetchTableData(dateValue, currentHub, toggleButtonValue);
  };

  const clearSelectedDate = () => {
    setSelectedDate();
  };

  return (
    <div>
      {!HubDetails ? (
        <>
          <p className="display-xs pending-transit__title">
            {intl.formatMessage({ id: 'ops_control.pending_transit.header' })}
          </p>

          <div className="pending-transit__header">
            <BRToggleButton
              loading={loading}
              buttonsList={TOGGLE_BUTTONS_LIST}
              setValue={setToggleButtonValue}
              value={toggleButtonValue}
              changeToggleButton={changeToggleButton}
            />
            <PendingTransitFilter
              loading={loading}
              intl={intl}
              allHubs={allHubs}
              currentHub={currentHub}
              selectedDate={selectedDate}
              setCurrentHub={setCurrentHub}
              setSelectedDate={setSelectedDate}
              handleHubChange={handleHubChange}
              changeDateValue={changeDateValue}
            />
          </div>
          <div className="pending-transit__cards-container hubsOverview-container">
            {SINGLE_CARDS_INFOS(
              cardData,
              exportCardsData,
              exportDispatchingCard
            ).map(
              (
                {
                  data,
                  title,
                  exportFunction,
                  deliveryReceivedState,
                  zeroAttemptsOrders,
                  exceededSlaOrders
                },
                index
              ) => {
                return (
                  <SingleInfoCard
                    key={index}
                    selectedDate={selectedDate || initalDate}
                    initialDate={initalDate}
                    loading={loading}
                    data={data}
                    title={title}
                    hubId={currentHub}
                    exportFunction={exportFunction}
                    deliveryReceivedState={deliveryReceivedState}
                    zeroAttemptsOrders={zeroAttemptsOrders}
                    exceededSlaOrders={exceededSlaOrders}
                    exportApi={exportDispatchingAllHubsDeliveries}
                  />
                );
              }
            )}
          </div>
          <div className="pending-transit__hubs-table-container">
            <BRTable
              showPagination={false}
              isLoading={loading}
              exportListFileFunction={exportTableData}
              listingData={tableData}
              emptyState={() => {
                return <BREmptyState />;
              }}
              pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
              columns={HUBS_OVERVIEW_TABLE_COLUMNS}
              hideFilterButton
              title={
                <div className="pending-transit__hubs-table-title">
                  <HubsIcon />
                  {intl.formatMessage({
                    id: 'ops_control.pending_transit.hubs_table.title'
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <HubView
          exportCardsData={exportCardsData}
          initalDate={initalDate}
          setCurrentHub={setCurrentHub}
          allHubs={allHubs}
          hubDetails={HubDetails}
          setHubDetails={setHubDetails}
          clearSelectedDate={clearSelectedDate}
          intl={intl}
        />
      )}
    </div>
  );
};

export default injectIntl(HubsOverview);
