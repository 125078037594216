import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';

import { updateBusiness } from 'services/business';
import useFeatureToggle from 'hooks/useFeatureToggle';
import {
  BOSTA_CREDIT_FEAT_TOGGLE,
  BOSTA_CREDITS_TOGGLE_CONTEXT
} from 'constants/wallet';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import InsurancePlanSelector from 'components/Businesses/InsurancePlanSelector/InsurancePlanSelector';
import BusinessValiditySwitch from './BusinessSwitch/BusinessValiditySwitch/BusinessValiditySwitch';
import BusinessFulfillmentSwitch from './BusinessSwitch/BusinessFulfillmentSwitch/BusinessFulfillmentSwitch';
import BusinessRecurringPickupsSwitch from './BusinessSwitch/BusinessRecurringPickupsSwitch/BusinessRecurringPickupsSwitch';
import BusinessThirdAttemptSwitch from './BusinessSwitch/BusinessThirdAttemptSwitch/BusinessThirdAttemptSwitch';
import BusinessIntlShippingSwitch from './BusinessSwitch/BusinessIntlShippingSwitch/BusinessIntlShippingSwitch';
import BusinessIntlShippingActivationSwitch from './BusinessSwitch/BusinessIntlShippingActivationSwitch/BusinessIntlShippingActivationSwitch';
import FulfillmentServiceSelector from 'components/Businesses/FulfillmentServiceSelector/FulfillmentServiceSelector';
import BostaCreditLimit from './BostaCreditLimit/BostaCreditLimit';

import './BusinessSettingsPanel.less';

const BusinessSettingsPanel = ({ businessData, refreshData }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const {
    _id: businessId,
    insurancePlan = {},
    status: { value: businessStatus } = {},
    internationalShippingEnabled,
    minimumBostaCreditsAmountForOrderCreation
  } = businessData;

  const { isFeatureEnabled: isLimitBostaCreditsEnabled } = useFeatureToggle(
    BOSTA_CREDIT_FEAT_TOGGLE,
    {
      subFeatureName:
        BOSTA_CREDITS_TOGGLE_CONTEXT.LIMIT_ORDER_AND_PICKUP_CREATION
    }
  );

  const submitBusinessUpdate = useCallback(
    async (payload, { callback, onSuccess = () => {}, onError = () => {} }) => {
      setLoading(true);
      try {
        const res = await (callback || updateBusiness)(businessId, payload);
        notify(
          res.message ||
            intl.formatMessage({ id: 'businesses.success_update' }),
          'success'
        );
        onSuccess(res);
      } catch (e) {
        notify(e.message);
        onError(e);
      } finally {
        refreshData?.();
        setLoading(false);
      }
    },
    [businessId, refreshData]
  );

  const switchProps = {
    businessData,
    submitBusinessUpdate
  };

  return (
    <LoadingWrapper loading={loading}>
      <div className="br-business-details__settings-panel-controls">
        <div>
          <span>
            {intl.formatMessage(
              {
                id: 'businesses.details.manageBusiness.business_status'
              },
              {
                businessStatus
              }
            )}
          </span>

          <BusinessValiditySwitch {...switchProps} />
        </div>

        {/* <div>
          <span>
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.fulfillment_service'
            })}
          </span>

          <BusinessFulfillmentSwitch {...switchProps} />
        </div> */}

        <div>
          <span>
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.recurring_pickups'
            })}
          </span>

          <BusinessRecurringPickupsSwitch {...switchProps} />
        </div>

        <div>
          <span>
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.enable_international_shipping'
            })}
          </span>

          <BusinessIntlShippingSwitch {...switchProps} />
        </div>

        <div>
          <span
            className={classnames({
              'disabled-switch': !internationalShippingEnabled
            })}
          >
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.activate_international_shipping'
            })}
          </span>

          <BusinessIntlShippingActivationSwitch {...switchProps} />
        </div>

        <div>
          <span>
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.third_attempt'
            })}
          </span>

          <BusinessThirdAttemptSwitch {...switchProps} />
        </div>

        <div>
          <span>
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.insurance_plan'
            })}
          </span>

          <InsurancePlanSelector
            _id={businessId}
            insurancePlan={insurancePlan}
            onSuccess={refreshData}
          />
        </div>
        <div>
          <span>
            {intl.formatMessage({
              id: 'businesses.details.manageBusiness.fulfillment_service'
            })}
          </span>

          <FulfillmentServiceSelector
            businessId={businessId}
            businessData={businessData}
            onSuccess={refreshData}
          />
        </div>
        {isLimitBostaCreditsEnabled && (
          <BostaCreditLimit
            minimumBostaCreditsAmountForOrderCreation={
              minimumBostaCreditsAmountForOrderCreation
            }
            submitBusinessUpdate={submitBusinessUpdate}
          />
        )}
      </div>
    </LoadingWrapper>
  );
};

export default BusinessSettingsPanel;
