import { COUNTRIES } from 'constants/country-data';

export const NUMBER_TEXT = 'NUMBER_TEXT';
export const TEXT = 'text';
export const ALPHA_NUMERIC = 'ALPHA_NUMERIC';
export const phoneRegExpEgypt = /^1[0-2|5]{1}[0-9]{8}$/;
export const phoneRegExpSaudi = /^(\+9665|05)([013-9][0-9]{7})$/;
export const phoneRegExpEgyptWithZero = /^01[0-2|5]{1}[0-9]{8}$/;
export const phoneRegExpSaudiLanLineAndPhone =
  /^(\+9665|05)([013-9][0-9]{7})|^[0-9]{9}$/;
// /^(5)[013-9]|(01[1-4|6-7])([0-9]{7})$/;

export const FIXED_LINE = 0;

export const PHONE_NUMBER_MAX_LENGHT =
  JSON.parse(localStorage.getItem('userInfo'))?.country?.code ===
  COUNTRIES[1].codeName
    ? 20
    : 11;

export const AT_LEAST_EIGHT_LETTERS =
  /^[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-]{8,}$/;
export const AT_LEAST_ONE_DIGIT = /(?=.*\d)/;
export const AT_LEAST_ONE_UPPERCASE = /[A-Z]/;
export const AT_LEAST_ONE_SPECIAL_CHAR = /(?=.*(\W|_))/;

export const NO_EN_REGEX = /^(?!.*[a-zA-Z])/;
export const INCLUDES_AR_REGEX = /[\u0600-\u06FF]/;

export const INPUT_LANG = {
  AR: 'AR',
  EN: 'EN'
};
