export const BOSTA_ANSWERS = {
  NOTIFIED_CONSIGNEE_WE_RECEIVED_DELIVERY_AT_FIRST_HUB:
    'First Received Message',
  NOTIFIED_CONSIGNEE_THAT_DELIVERY_WILL_BE_DELIVERED_SOON: 'LM hub Message',
  NOTIFIED_CONSIGNEE_THAT_DELIVERY_IS_WITH_STAR: 'OFD message',
  ASK_FOR_LOCATION_AFTER_CONSIGNEE_REQUESTS_CONFIRMATION:
    'Location confirmation',
  ASK_IF_CONSIGNEE_WOULD_LIKE_STAR_TO_CALL_THEM: 'Delivery instructions',
  ASK_USER_TO_RATE_SERVICE: 'Asked the User to rate the Service',
  ASK_USER_TO_RATE_STAR: 'Asked the User to rate the Star',
  ASK_USER_FOR_REASON_FOR_SERVICE_RATING:
    'Asked the User For the Service Rate Reason',
  ASK_USER_FOR_REASON_CATEGORY_FOR_STAR_RATING: 'Asked For Star Rate Category',
  ASK_USER_FOR_REASON_FOR_STAR_RATING_CATEGORY: 'Asked For Star Rate Reason',
  THANK_USER_FOR_COMPLETING_RATING: 'Thanked the User For Rating',
  USER_ALREADY_RATED_PICKUP_OR_DELIVERY_AND_CONVERSATION_ENDED:
    'Conversiation Ended'
};

export const BOSTA_MESSAGE = 'BOSTA_MESSAGE';

export const CONSIGNEE_MESSAGE = 'CONSIGNEE_MESSAGE';

export const WHATS_APP_DATE_FORMAT = 'DD MMM, YYYY-hh:mm a';

export const WHATS_APP = 'whats_app';

export const WHATS_APP_RESCHUDLE_FORMAT = 'DD MMM';

export const WHATS_APP_STATUS = {
  DELIVERIED: 'delivered',
  READ: 'read',
  SENT: 'sent'
};
