import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  BOSTA_ANSWERS,
  BOSTA_MESSAGE,
  CONSIGNEE_MESSAGE,
  WHATS_APP_DATE_FORMAT,
  WHATS_APP_RESCHUDLE_FORMAT,
  WHATS_APP_STATUS
} from 'constants/whats-app';
import { getWhatsAppConversation } from 'services/shipments';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as Bosta } from 'assets/bosta-icons/bosta.svg';
import { ReactComponent as User } from 'assets/bosta-icons/user.svg';
import { ReactComponent as DoubleCheck } from 'assets/bosta-icons/Double check.svg';
import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';

import './WhatsAppLogs.less';

const WhatsAppLogs = ({ whatsAppLastMileActions, trackingNumber }) => {
  const intl = useIntl();

  const [allLogs, setAllLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useState();
  const fetchWhatsAppConversations = async () => {
    try {
      setLoading(true);
      const res = await getWhatsAppConversation(trackingNumber);
      console.log(res?.events);
      setAllLogs(constructChat(res?.events));
    } catch (error) {
      notify(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchWhatsAppConversations();
  }, []);

  const renderSentIcon = (status) => {
    switch (status) {
      case WHATS_APP_STATUS.DELIVERIED:
        return (
          <div className="iconWrapper-delivered">
            <DoubleCheck />
          </div>
        );

      case WHATS_APP_STATUS.READ:
        return (
          <div className="iconWrapper-read">
            <DoubleCheck />
          </div>
        );
      case WHATS_APP_STATUS.SENT:
        return (
          <div className="iconWrapper-read">
            <Check />
          </div>
        );
      default:
        return <></>;
    }
  };

  const MapLink = (lat, lng) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

    return (
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        {intl.formatMessage({
          id: 'deliveries.delivery_details.whats_app.location_sent'
        })}
      </a>
    );
  };

  const MapBostaMessages = (eventName) => {
    return BOSTA_ANSWERS[eventName];
  };

  const constructBostaMessages = (events) => {
    let BostaLogs = [];
    events.map((event) => {
      let Message = MapBostaMessages(event?.eventName);
      if (Message) {
        BostaLogs.push({
          MessageType: BOSTA_MESSAGE,
          Message,
          timeStamp: dayjs(event?.eventTime),
          status: event?.status
        });
      }
    });
    return BostaLogs;
  };
  const getInstructionsLocationTimeStamps = (events) => {
    let instructionsTimeStamp, locationTimeStamp;
    events.map((event) => {
      if (
        event?.eventName ===
        'ASK_FOR_LOCATION_AFTER_CONSIGNEE_REQUESTS_CONFIRMATION'
      ) {
        console.log('here');
        locationTimeStamp = dayjs(event?.eventTime);
      }
      if (
        event?.eventName === 'ASK_IF_CONSIGNEE_WOULD_LIKE_STAR_TO_CALL_THEM'
      ) {
        instructionsTimeStamp = dayjs(event?.eventTime);
      }
    });
    return { instructionsTimeStamp, locationTimeStamp };
  };

  const constructChat = (events) => {
    let BostaLogs = constructBostaMessages(events);
    let consigneeLogs = MapConsigneeMessages(events);
    return [...BostaLogs, ...consigneeLogs].sort((a, b) =>
      dayjs(a.timeStamp).diff(dayjs(b.timeStamp))
    );
  };

  const MapConsigneeMessages = (events) => {
    const { instructionsTimeStamp, locationTimeStamp } =
      getInstructionsLocationTimeStamps(events);
    let ConsigneeLogs = [];
    let { consigneeConfirmedDelivery, reschedule, cancel, changeLocation } =
      whatsAppLastMileActions;
    if (consigneeConfirmedDelivery) {
      const {
        isConfirmedDelivery,
        consigneeRequestedStarToCall,
        consigneeRequestedSilentDelivery,
        time,
        confirmedGeoLocation
      } = consigneeConfirmedDelivery;

      if (isConfirmedDelivery) {
        ConsigneeLogs.push({
          MessageType: CONSIGNEE_MESSAGE,
          timeStamp: dayjs(time),
          Message: intl.formatMessage({
            id: 'deliveries.delivery_details.whats_app.confirm_order'
          })
        });
        if (consigneeRequestedStarToCall || consigneeRequestedSilentDelivery) {
          ConsigneeLogs.push({
            MessageType: CONSIGNEE_MESSAGE,
            timeStamp: instructionsTimeStamp,
            Message: intl.formatMessage({
              id: 'deliveries.delivery_details.whats_app.delivery_instructions'
            })
          });
        }
        if (confirmedGeoLocation) {
          ConsigneeLogs.push({
            MessageType: CONSIGNEE_MESSAGE,
            timeStamp: locationTimeStamp,
            Message: MapLink(confirmedGeoLocation[0], confirmedGeoLocation[1])
          });
        }
      }
    }
    if (reschedule) {
      ConsigneeLogs.push({
        MessageType: CONSIGNEE_MESSAGE,
        timeStamp: dayjs(reschedule?.rescheduledAt),
        Message: intl.formatMessage(
          {
            id: 'deliveries.delivery_details.whats_app.order_rescheduled'
          },
          {
            date: dayjs(reschedule?.rescheduledTo)?.format(
              WHATS_APP_RESCHUDLE_FORMAT
            )
          }
        )
      });
    }
    if (cancel) {
      ConsigneeLogs.push({
        MessageType: CONSIGNEE_MESSAGE,
        timeStamp: dayjs(cancel?.cancelledAt),
        Message: intl.formatMessage(
          {
            id: 'deliveries.delivery_details.whats_app.order_canceled'
          },
          { reason: cancel?.cancellationReason }
        )
      });
    }
    if (changeLocation) {
      ConsigneeLogs.push({
        MessageType: CONSIGNEE_MESSAGE,
        timeStamp: dayjs(changeLocation?.changedAt),
        Message: intl.formatMessage(
          {
            id: 'deliveries.delivery_details.whats_app.sending_location'
          },
          { location: changeLocation?.locationChangedTo }
        )
      });
    }
    return ConsigneeLogs;
  };

  return (
    <div className="chat-wrapper">
      {allLogs.map((log) => {
        return (
          <div
            className={classNames('message-wrapper', {
              'bosta-message-wrapper': log.MessageType === BOSTA_MESSAGE,
              'consignee-message-wrapper': log.MessageType === CONSIGNEE_MESSAGE
            })}
          >
            {log.MessageType === BOSTA_MESSAGE && (
              <span className="logo-wrapper">
                <Bosta />
              </span>
            )}
            <div className="message-content">
              <span className="body">{log?.Message}</span>
              <span className="time-stamp caption">
                {log?.timeStamp?.format(WHATS_APP_DATE_FORMAT)}
                {log?.status && <span>{renderSentIcon(log?.status)}</span>}
              </span>
            </div>
            {log.MessageType === CONSIGNEE_MESSAGE && (
              <span className="logo-wrapper">
                <User />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WhatsAppLogs;
