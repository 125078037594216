export const SUPER_ADMIN = 'SUPER_ADMIN';
export const LAST_MILE_COORDINATOR = 'LAST_MILE_COORDINATOR';
export const FIRST_MILE_COORDINATOR = 'FIRST_MILE_COORDINATOR';
export const SUPPLY_LEAD = 'SUPPLY_LEAD';
export const SALES = 'SALES';
export const ACCOUNTANT = 'ACCOUNTANT';
export const BUSINESS_ADMIN = 'BUSINESS_ADMIN';
export const CUSTOMER = 'CUSTOMER';
export const BUSINESS_CUSTOMER = 'BUSINESS_CUSTOMER';
export const STAR = 'STAR';
export const WAREHOUSE_CLERK = 'WAREHOUSE_CLERK';
export const HUB_COORDINATOR = 'HUB_COORDINATOR';
export const OPERATIONS_MANAGER = 'OPERATIONS_MANAGER';
export const SALES_MANAGER = 'SALES_MANAGER';
export const HUB_LEADER = 'HUB_LEADER';
export const OPERATOR = 'OPERATOR';
export const EXPERIENCE = 'EXPERIENCE';
export const OUTBOUND_AGENT = 'OUTBOUND_AGENT';
export const FLEET_PERFORMANCE = 'FLEET_PERFORMANCE';
export const HEAD_OF_OPS = 'HEAD_OF_OPS';
export const AREA_MANAGER = 'AREA_MANAGER';
export const FLEET_MANAGER = 'FLEET_MANAGER';
export const CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT';
export const VENDOR_MANAGER = 'VENDOR_MANAGER';
export const ON_BOARDING = 'ON_BOARDING';
export const COMMERCIAL_MANAGER = 'COMMERCIAL_MANAGER';
export const FULFILLMENT_SUPERVISOR = 'FULFILLMENT_SUPERVISOR';
export const FULFILLMENT_CLERK = 'FULFILLMENT_CLERK';

export const restrictBusinessRoles = {
  COMB_1: [HUB_COORDINATOR, FIRST_MILE_COORDINATOR, LAST_MILE_COORDINATOR],
  COMB_2: [
    HUB_COORDINATOR,
    HUB_LEADER,
    FIRST_MILE_COORDINATOR,
    LAST_MILE_COORDINATOR
  ],
  COMB_3: [HUB_LEADER, FIRST_MILE_COORDINATOR, LAST_MILE_COORDINATOR]
};

const checkIfRoleCourrierIsApplicable = (roles) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (
    userInfo?.vendor?.isDefaultCourier ||
    userInfo?.vendor?.isDefaultCourier === undefined
  ) {
    return roles;
  } else {
    return [];
  }
};

const FULFILLMENT_ROLES = [FULFILLMENT_SUPERVISOR, FULFILLMENT_CLERK];

const aclMatrix = {
  THREE_PL: ['HUB_LEADER', 'WAREHOUSE_CLERK', ...FULFILLMENT_ROLES],
  BUSINESSES: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'ACCOUNTANT',
    ...checkIfRoleCourrierIsApplicable(['WAREHOUSE_CLERK', 'HUB_LEADER']),
    'OPERATIONS_MANAGER',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'CUSTOMER_SUPPORT',
    'OUTBOUND_AGENT',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'COMMERCIAL_MANAGER',
    'OPERATOR',
    'EXPERIENCE',
    ...FULFILLMENT_ROLES
  ],
  BUSINESSES_DETAILS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'CUSTOMER_SUPPORT',
    'LIVE_OPS_AGENT',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'COMMERCIAL_MANAGER',
    ...checkIfRoleCourrierIsApplicable([
      'WAREHOUSE_CLERK',
      'HUB_LEADER',
      'FLEET_MANAGER'
    ]),
    'OPERATOR',
    'EXPERIENCE',
    ...FULFILLMENT_ROLES
  ],
  BUSINESSES_EDIT: [
    'SUPER_ADMIN',
    'SALES',
    'SALES_MANAGER',
    'ON_BOARDING',
    'CUSTOMER_SUPPORT',
    'ACCOUNTANT',
    'COMMERCIAL_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  ANALYTICS: [
    'SUPER_ADMIN',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'HEAD_OF_OPS',
    ...FULFILLMENT_ROLES
  ],
  STARS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'LIVE_OPS_AGENT',
    'FLEET_PERFORMANCE',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    'HUB_LEADER',
    'AREA_MANAGER',
    'OPS_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'HUB_COORDINATOR',
    'OPERATOR',
    'EXPERIENCE',
    'COMMERCIAL_MANAGER',
    'VENDOR_MANAGER',
    'WAREHOUSE_CLERK',
    'HUB_CASHIER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  STARS_EDIT: [
    'SUPER_ADMIN',
    'SUPPLY_LEAD',
    'OPERATIONS_MANAGER',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    'HEAD_OF_FLEET',
    'HEAD_OF_OPS',
    'FLEET_PERFORMANCE',
    'ACCOUNTANT',
    ...FULFILLMENT_ROLES
  ],
  STARS_CREATE: [
    'SUPER_ADMIN',
    'SUPPLY_LEAD',
    'OPERATIONS_MANAGER',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'FLEET_PERFORMANCE',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  STARS_EXCLUDE_FROM_LIABILITY: [
    'SUPER_ADMIN',
    'ACCOUNTANT',
    'OPERATOR',
    'EXPERIENCE',
    'COMMERCIAL_MANAGER',
    'FLEET_MANAGER',
    'OPERATIONS_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  STARS_EXPORT_REPORT: [
    'SUPER_ADMIN',
    'ACCOUNTANT',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    'FLEET_PERFORMANCE',
    'HEAD_OF_FLEET',
    'COMMERCIAL_MANAGER',
    'HUB_CASHIER',
    ...FULFILLMENT_ROLES
  ],
  STARS_CYCLES: [
    'SUPER_ADMIN',
    'FLEET_MANAGER',
    'AREA_MANAGER',
    'OPS_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'OPERATIONS_MANAGER',
    ...checkIfRoleCourrierIsApplicable([
      'HUB_LEADER',
      'FLEET_PERFORMANCE',
      'FLEET_ACQUISITION'
    ]),
    ...FULFILLMENT_ROLES
  ],
  STARS_MAP: [
    'SUPER_ADMIN',
    'FLEET_PERFORMANCE',
    'FLEET_MANAGER',
    'OPS_MANAGER',
    'LIVE_OPS_AGENT',
    'OPERATOR',
    'EXPERIENCE',
    'OPERATIONS_MANAGER',
    'AREA_MANAGER',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    ...FULFILLMENT_ROLES
  ],
  BONUSES_AND_DEDUCTIONS: [
    'SUPER_ADMIN',
    'AREA_MANAGER',
    'OPS_MANAGER',
    'FLEET_PERFORMANCE',
    'FLEET_MANAGER',
    'HUB_MANAGER',
    'OPS_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'OPERATIONS_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  APPROVE_REJECT_BONUS_AND_DEDUCTION: [
    'SUPER_ADMIN',
    'AREA_MANAGER',
    'OPS_MANAGER',
    'FLEET_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'OPERATIONS_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  REMOVE_PENDING_BONUS_AND_DEDUCTION: [
    'SUPER_ADMIN',
    'FLEET_MANAGER',
    'FLEET_PERFORMANCE',
    'AREA_MANAGER',
    'OPS_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'OPERATIONS_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  REMOVE_REJECTED_BONUS_AND_DEDUCTION: [
    'SUPER_ADMIN',
    'HUB_MANAGER',
    'OPS_MANAGER',
    'AREA_MANAGER',
    'HEAD_OF_OPS',
    'OPERATIONS_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  STAR_ALLOWANCES: [
    'SUPER_ADMIN',
    'HEAD_OF_FLEET',
    ...checkIfRoleCourrierIsApplicable(['FLEET_MANAGER']),
    ...FULFILLMENT_ROLES
  ],
  STAR_PERFROMANCE: [
    'SUPER_ADMIN',
    'FLEET_PERFORMANCE',
    'FLEET_MANAGER',
    'AREA_MANAGER',
    'OPERATIONS_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  VENDOR: ['SUPER_ADMIN', ...FULFILLMENT_ROLES],
  DELIVERIES: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'LIVE_OPS_AGENT',
    'OUTBOUND_AGENT',
    'FLEET_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'HUB_COORDINATOR',
    'CUSTOMER_SUPPORT',
    'LIVE_OPS',
    'COMMERCIAL_MANAGER',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  UPLOAD_SHIPMENT_SHORTCUT: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'LIVE_OPS_AGENT',
    'OUTBOUND_AGENT',
    'FLEET_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'HUB_COORDINATOR',
    'CUSTOMER_SUPPORT',
    'LIVE_OPS',
    'COMMERCIAL_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  BOSTA_BOX: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'COMMERCIAL_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  BORROW: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'COMMERCIAL_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  QUALITY: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'LIVE_OPS_AGENT',
    'COMMERCIAL_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  REJECTED_RETURN_TAB_ACCESS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'LIVE_OPS_AGENT',
    'COMMERCIAL_MANAGER',
    'VENDOR_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  DELIVERIES_DETAILS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'HEAD_OF_OPS',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'OUTBOUND_AGENT',
    'ON_BOARDING',
    'LIVE_OPS_AGENT',
    'HEAD_OF_FLEET',
    'HUB_COORDINATOR',
    'CUSTOMER_SUPPORT',
    'COMMERCIAL_MANAGER',
    'VENDOR_MANAGER',
    ...checkIfRoleCourrierIsApplicable([
      'HUB_LEADER',
      'WAREHOUSE_CLERK',
      'FLEET_MANAGER'
    ]),
    ...FULFILLMENT_ROLES
  ],
  DELIVERIES_CREATE: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'OPERATIONS_MANAGER',
    'HEAD_OF_OPS',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  DELIVERIES_EDIT: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SUPPLY_LEAD',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HEAD_OF_OPS',
    'HUB_LEADER',
    'ON_BOARDING',
    'HUB_COORDINATOR',
    ...FULFILLMENT_ROLES
  ],
  ROUTES: [
    'SUPER_ADMIN',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  ROUTES_CREATE_EDIT: [
    'SUPER_ADMIN',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  PICKUPS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'OPERATIONS_MANAGER',
    'ON_BOARDING',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'VENDOR_MANAGER',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    ...FULFILLMENT_ROLES
  ],
  CREATE_PICKUP_SHORTCUT: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'OPERATIONS_MANAGER',
    'ON_BOARDING',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  PICKUPS_DETAILS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'OPERATIONS_MANAGER',
    'ON_BOARDING',
    'OUTBOUND_AGENT',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'VENDOR_MANAGER',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    ...FULFILLMENT_ROLES
  ],
  HUB_OPERATIONS: [
    'SUPER_ADMIN',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  HUB: [
    'SUPER_ADMIN',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'VENDOR_MANAGER',
    'ACCOUNTANT',
    'HUB_CASHIER',
    ...FULFILLMENT_ROLES
  ],
  DISPATCH_RTO_TAB: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    ...checkIfRoleCourrierIsApplicable([
      'HUB_LEADER',
      'WAREHOUSE_CLERK',
      'VENDOR_MANAGER'
    ]),
    ...FULFILLMENT_ROLES
  ],
  DEBRIEF_CASH: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'ACCOUNTANT',
    'HUB_CASHIER',
    ...checkIfRoleCourrierIsApplicable([
      'HUB_LEADER',
      'WAREHOUSE_CLERK',
      'VENDOR_MANAGER'
    ]),
    ...FULFILLMENT_ROLES
  ],

  HUB_MANAGEMENT: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'OPERATOR',
    'HEAD_OF_OPS',
    'VENDOR_MANAGER',
    'HUB_LEADER',
    'HUB_CASHIER',
    ...FULFILLMENT_ROLES
  ],

  HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'OPERATOR',
    'HEAD_OF_OPS',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'VENDOR_MANAGER']),
    ...FULFILLMENT_ROLES
  ],

  HUB_CREATE_EDIT: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'OPERATOR',
    'HEAD_OF_OPS',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  HUB_MANAGEMENT_OPERATOR: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'OPERATOR',
    'HEAD_OF_OPS',
    ...FULFILLMENT_ROLES
  ],
  HUBS: [
    'SUPER_ADMIN',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'OPERATOR',
    'EXPERIENCE',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'LAST_MILE_COORDINATOR',
    'AREA_MANAGER',
    'VENDOR_MANAGER',
    'ACCOUNTANT',
    'HUB_CASHIER',
    ...FULFILLMENT_ROLES
  ],
  HUB_NOTIFICATIONS: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'OPERATOR',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'LAST_MILE_COORDINATOR',
    'AREA_MANAGER',
    ...checkIfRoleCourrierIsApplicable([
      'HUB_LEADER',
      'WAREHOUSE_CLERK',
      'VENDOR_MANAGER'
    ]),
    ...FULFILLMENT_ROLES
  ],
  SHELVES_SCAN: [
    'SUPER_ADMIN',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'FIRST_MILE_COORDINATOR',
    'LAST_MILE_COORDINATOR',
    'AREA_MANAGER',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  COD_TRANSACTIONS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'ACCOUNTANT',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'COMMERCIAL_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  OUTBOUND: ['SUPER_ADMIN', 'OUTBOUND_AGENT', ...FULFILLMENT_ROLES],
  FLYERS: [
    'SUPER_ADMIN',
    'FIRST_MILE_COORDINATOR',
    'LAST_MILE_COORDINATOR',
    ...FULFILLMENT_ROLES
  ],
  HOLDERS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'HUB_LEADER',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    ...FULFILLMENT_ROLES
  ],
  UPLOAD_CSV: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    ...FULFILLMENT_ROLES
  ],
  SETTINGS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'HUB_LEADER',
    'ON_BOARDING',
    'CUSTOMER_SUPPORT',
    'LIVE_OPS_AGENT',
    'FLEET_MANAGER',
    'HEAD_OF_OPS',
    'HEAD_OF_FLEET',
    'HUB_COORDINATOR',
    'COMMERCIAL_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  PRICEPLANS: [
    'SUPER_ADMIN',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'OPERATIONS_MANAGER',
    'HEAD_OF_OPS',
    ...FULFILLMENT_ROLES
  ],
  PRICING: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'SALES',
    'SUPPLY_LEAD',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    'ON_BOARDING',
    'CUSTOMER_SUPPORT',
    'HEAD_OF_OPS',
    'HUB_COORDINATOR',
    'COMMERCIAL_MANAGER',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],

  CREATEPRICEPLANS: [
    'SUPER_ADMIN',
    'SALES',
    'SALES_MANAGER',
    'SALES_DIRECTOR',
    ...FULFILLMENT_ROLES
  ],
  RATING_STARS: [
    'LIVE_OPS_AGENT',
    'SUPER_ADMIN',
    'OUTBOUND_AGENT',
    'CUSTOMER_SUPPORT',
    'FLEET_MANAGER',
    'LIVE_OPS',
    ...FULFILLMENT_ROLES
  ],
  LIVEOPS: [
    'LIVE_OPS_AGENT',
    'SUPER_ADMIN',
    'HEAD_OF_FLEET',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  INVENTORY: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'SALES_MANAGER',
    'SALES',
    'ON_BOARDING',
    'ACCOUNTANT',
    'CUSTOMER_SUPPORT',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  WALLET: [
    'SUPER_ADMIN',
    'CUSTOMER_SUPPORT',
    'SALES_MANAGER',
    'ACCOUNTANT',
    'COMMERCIAL_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  ACTION_CENTER: [
    'SUPER_ADMIN',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    'VENDOR_MANAGER',
    'OPERATIONS_MANAGER',
    'AREA_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  AUDIT_ACTIONS: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'AREA_MANAGER',
    'OPERATOR',
    'EXPERIENCE',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER']),
    ...FULFILLMENT_ROLES
  ],
  DELIVERY_NOTES: [
    'SUPER_ADMIN',
    'CUSTOMER_SUPPORT',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    'VENDOR_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  CASHIER: ['ACCOUNTANT', 'SUPER_ADMIN', 'HUB_CASHIER', ...FULFILLMENT_ROLES],
  OFD_EDITS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'AREA_MANAGER',
    'OPERATIONS_MANAGER',
    'OPERATOR',
    'EXPERIENCE',
    ...checkIfRoleCourrierIsApplicable(['HUB_LEADER', 'WAREHOUSE_CLERK']),
    ...FULFILLMENT_ROLES
  ],
  FLEET_TEAM: [
    'FLEET_PERFORMANCE',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  EXPERIENCE_TEAM: ['OPERATOR', ...FULFILLMENT_ROLES],
  FINANCE_TEAM: ['ACCOUNTANT', ...FULFILLMENT_ROLES],
  GEOFENCES: ['SUPER_ADMIN', ...FULFILLMENT_ROLES],
  BIB_ANALYTICS: [
    'SUPER_ADMIN',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    'VENDOR_MANAGER',
    'OPERATOR',
    'EXPERIENCE',
    'OPS_MANAGER',
    'FLEET_MANAGER',
    'AREA_MANAGER',
    'OPERATIONS_MANAGER',
    ...FULFILLMENT_ROLES
  ],

  STARS_RESET_LIABILITY: ['SUPER_ADMIN', 'ACCOUNTANT', ...FULFILLMENT_ROLES],
  DISPATCHING: [
    'SUPER_ADMIN',
    'AREA_MANAGER',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    'WAREHOUSE_CLERK',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'EXPERIENCE',
    ...FULFILLMENT_ROLES
  ],
  THREE_PL_INTEGRATIONS: [
    'SUPER_ADMIN',
    'LAST_MILE_COORDINATOR',
    'FIRST_MILE_COORDINATOR',
    'WAREHOUSE_CLERK',
    'OPERATIONS_MANAGER',
    'HUB_LEADER',
    ...FULFILLMENT_ROLES
  ],
  ZONING: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'AREA_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  VIEW_HUBS_PERFORMANCE: [
    'OPS_MANAGER',
    'OPERATOR',
    'FLEET_MANAGER',
    'AREA_MANAGER',
    'VENDOR_MANAGER',
    'SUPER_ADMIN',
    'HUB_LEADER',
    ...FULFILLMENT_ROLES
  ],
  STARS_UNLINK_PHONE_NUMBER: [
    'SUPER_ADMIN',
    'SUPPLY_LEAD',
    'OPERATIONS_MANAGER',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    'HEAD_OF_FLEET',
    'HEAD_OF_OPS',
    'FLEET_PERFORMANCE',
    'HUB_LEADER',
    ...FULFILLMENT_ROLES
  ],
  ZONING: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'AREA_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  HUBS_PERFORMANCE_ACCESS: [
    'SUPER_ADMIN',
    'OPERATOR',
    'EXPERIENCE',
    'OPERATIONS_MANAGER',
    'FLEET_MANAGER',
    'AREA_MANAGER',
    'VENDOR_MANAGER',
    'OPS_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  EDIT_SEND_AUTOMATED_CALLS: [
    'SUPER_ADMIN',
    'EXPERIENCE',
    'SALES_MANAGER',
    'COMMERCIAL_MANAGER',
    'CUSTOMER_SUPPORT',
    ...FULFILLMENT_ROLES
  ],
  ROLES_PERMISSIONS: ['SUPER_ADMIN', ...FULFILLMENT_ROLES],
  API_KEY_ACTIONS: ['SUPER_ADMIN', ...FULFILLMENT_ROLES],
  FLEET_TEAM: [
    'FLEET_PERFORMANCE',
    'FLEET_ACQUISITION',
    'FLEET_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  EXPERIENCE_TEAM: ['OPERATOR', ...FULFILLMENT_ROLES],
  FINANCE_TEAM: ['ACCOUNTANT', 'SUPER_ADMIN', ...FULFILLMENT_ROLES],
  RECEIVED_BY_MISTAKE: [
    'SUPER_ADMIN',
    'OPERATOR',
    'EXPERIENCE',
    ...FULFILLMENT_ROLES
  ],
  BOSTA_CODING: [
    'SUPER_ADMIN',
    'OPERATIONS_MANAGER',
    'AREA_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  VIEW_ALL_TICKETS: [
    'SUPER_ADMIN',
    'COMMERCIAL_MANAGER',
    'ON_BOARDING',
    'SALES_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  PACKAGING_ACCESS: [
    'SUPER_ADMIN',
    'ACCOUNTANT',
    'OPERATIONS_MANAGER',
    ...FULFILLMENT_ROLES
  ],
  PACKAGING_ORDER_PAGE: ['SUPER_ADMIN', 'LIVE_OPS_AGENT', ...FULFILLMENT_ROLES],
  SUBSCRIPTIONS: [
    'SUPER_ADMIN',
    'ACCOUNTANT',
    'COMMERCIAL_MANAGER',
    'SALES_MANAGER',
    'SALES'
  ],
  LOST_OR_DAMAGED_ORDERS_MODAL: ['HUB_LEADER', 'HUB_COORDINATOR']
};

export default aclMatrix;
