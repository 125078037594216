import { useState, useRef, useContext } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { openModal } from 'utils/modal';
import { SllrWrapper } from 'contexts/sllr.context';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import SelectBusiness from 'components/NewFulfillment/FulfillmentInbound/components/SelectBusiness/SelectBusiness';
import ProductsTable from 'components/NewFulfillment/FulfillmentProducts/components/ProductsList/components/ProductsTable/ProductsTable';
import BRButton from 'components/BRButton/BRButton';
import AddProductModal from 'components/NewFulfillment/FulfillmentProducts/components/ProductsList/components/AddProductModal/AddProductModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';

const OmnifulProductsList = ({ intl }) => {
  const { isLoading } = useContext(SllrWrapper);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [fetchedBusinesses, setFetchedBusinesses] = useState([]);

  const tableRef = useRef();

  const getBusinessName = (businessId) => {
    return (
      selectedBusiness?.name ||
      fetchedBusinesses.find(({ _id }) => businessId === _id)?.name
    );
  };

  const refreshTable = () => {
    tableRef?.current();
  };

  const handleOpenAddProductModal = () => {
    openModal(AddProductModal, {
      businesses: fetchedBusinesses,
      refreshTable
    });
  };

  return (
    <Container
      className="br-fulfillment-products"
      header={
        <BRHeader
          title={intl.formatMessage({
            id: 'fulfillment_products.title'
          })}
        />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div>
            <SelectBusiness
              className="br-fulfillment-products__select-business"
              label={intl.formatMessage({
                id: 'fulfillment_inbound.po_filters.business.label'
              })}
              placeholder={intl.formatMessage({
                id: 'fulfillment_inbound.po_filters.business.placeholder'
              })}
              selectedBusiness={selectedBusiness}
              setSelectedBusiness={setSelectedBusiness}
              setFetchedBusinesses={setFetchedBusinesses}
              isOmnifulFulfillment
            />
            <div className="br-add-product-btn">
              <BRButton
                type="primary"
                label={intl.formatMessage({
                  id: 'fulfillment_inbound.create_po.products.select_products_modal.add_product'
                })}
                prefixIcon={<PlusIcon />}
                onClick={handleOpenAddProductModal}
                disabled={!fetchedBusinesses.length}
              />
            </div>
            <ProductsTable
              getBusinessName={getBusinessName}
              selectedBusinessId={selectedBusiness?._id}
              tableRef={tableRef}
            />
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(withRouter(OmnifulProductsList));
