import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Dropdown, Menu, Select, Input, Tooltip, Tag } from 'antd';
import { MoreOutlined, InfoCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import _debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';

import { DELIVERY_TYPES } from 'constants/Deliveries';
import {
  EXCEPTION_ORDERS_CODES,
  ACTION_TYPES,
  ACTION_TYPES_TAG_COLOR,
  exceptionsMapping,
  DELIVERIES_SCAN_TYPES
} from 'constants/hubs';
import {
  TAB_KEYS,
  EXCEPTIONS_STATE_ACTIONS_MAPPING,
  DELIVERY_ACTIONS_MAPPING,
  REJECTED_RETURN
} from 'constants/debrief';
import {
  RETURN_REJECTED_EXCEPTION_CODES,
  CANCELED_BY_SHIPPER,
  CUSTOMER_REFUSED_RECEIVING,
  POSTPONED_ORDER,
  CANCELED_CUSTOMER_WANTS_TO_OPEN_PACKAGE
} from 'constants/shipments';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import { receivePackagesAtHub, scanDeliveries } from 'services/hubs';
import { openModal } from 'utils/modal';
import { getDeliveryType } from 'utils/deliveries';
import { getOrderAge, isReturnOrder } from 'utils/shipmentDetails';
import {
  getDeliveryPendingActions,
  isInvalidMultiPackageScan
} from 'utils/hubs';
import SoundsService from 'common/Sounds';
import {
  getAttemptsTypes,
  isMaxNumOfAttempts
} from 'common/countries/countries-mapping';
import { numberRegex } from 'utils/helpers';

import {
  DeleteModal,
  CreateTicketModal
} from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/TableActionsModals/TableActionsModals';
import { notify } from 'components/Notify/Notify';
import SwappingTables from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/SwappingTables/SwappingTables';
import BRScanningPopup from 'components/BRScanningPopup/BRScanningPopup';
import MultiPackagesCount from 'components/MultiPackages/components/MultiPackagesCount/MultiPackagesCount';
import MissingMultiPackagesModal from 'components/MultiPackages/components/MissingMultiPackagesModal/MissingMultiPackagesModal';
import BRButton from 'components/BRButton/BRButton';
import UploadRejectModal from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/UploadRejectModal/UploadRejectModal';

import './ExceptionsTab.less';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const ExceptionsTab = ({
  starData,
  intl,
  hubName,
  getExceptions,
  nextWorkingDay,
  handleConfirmReceiving,
  setIsConfirmReceivingDisabled
}) => {
  const [exceptions, setExceptions] = useState([]);
  const [isFilterUsed, setFilterUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [scannedExceptionItems, setScannedExceptionItems] = useState([]);
  const [scannedTrackingNumber, setScannedTrackingNumber] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [trackingNumberInputValue, setTrackingNumberInputValue] = useState('');

  const trackingNumberRef = useRef('');
  let trackingNumberCharacters = '';

  const { RETURN, DELIVERY, PICKUP } = getAttemptsTypes();
  const {
    RESCHEDULED_TO_TOMORROW,
    RESCHEDULED_TO_DATE,
    ASSIGN_TO_ON_HOLD,
    ASSIGN_TO_RTO,
    ASSIGN_TO_RETURN_ISSUES
  } = EXCEPTIONS_STATE_ACTIONS_MAPPING;
  const { RTO, CASH_COLLECTION, CRP } = ORDER_TYPES_VALUE;

  const sharedColumns = [
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.tracking_number`
      }),
      dataIndex: '',
      width: 70,
      render: (item) => (
        <div className="br-exception-tab__tracking-number-cell">
          <span>
            <Link target="_blank" to={`/deliveries/${item._id}/details`}>
              {item.trackingNumber}
            </Link>
          </span>
        </div>
      )
    },
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.type`
      }),
      dataIndex: '',
      width: 100,
      render: getDeliveryType
    }
  ];

  const notAvailable = intl.formatMessage({
    id: `common.empty_field`
  });

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const renderOrderAge = (delivery) => {
    const { orderAge, tooltip } = getOrderAge(delivery);
    return (
      <div className="vertically-centered">
        {!isNaN(orderAge) ? orderAge : notAvailable}{' '}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderNoOfAttempts = (delivery) => {
    const {
      type,
      collectedFromConsignee,
      attemptsCount = 0,
      forwardAttemptsCount = 0
    } = delivery || {};
    let noOfAttempts = notAvailable;
    let tooltipId = null;

    if (type === CASH_COLLECTION) {
      noOfAttempts = forwardAttemptsCount;
      tooltipId = 'collection';
    } else if (type === RTO || collectedFromConsignee) {
      noOfAttempts = attemptsCount - forwardAttemptsCount;
      tooltipId = 'return';
    } else {
      noOfAttempts = attemptsCount;
      tooltipId = 'delivery';
    }

    return (
      <div className="vertically-centered">
        {noOfAttempts}{' '}
        {tooltipId && (
          <Tooltip
            title={intl.formatMessage({
              id: `hubs.packages_debrief.exceptions_tab.attempts_tooltips.${tooltipId}`
            })}
          >
            <InfoCircleFilled />
          </Tooltip>
        )}
      </div>
    );
  };

  const addImagesToException = ({
    delivery,
    imagesUrls,
    isUploadFromButton
  }) => {
    if (isUploadFromButton) {
      setExceptions((prevItems) =>
        prevItems.map((item) =>
          item?.trackingNumber === delivery?.trackingNumber
            ? {
                ...item,
                rejectedReturnImages: [
                  ...(delivery?.rejectedReturnImages || []),
                  ...imagesUrls
                ]
              }
            : item
        )
      );
    } else {
      setScannedExceptionItems((prevItems) => [
        ...prevItems,
        { ...delivery, rejectedReturnImages: imagesUrls }
      ]);
    }
  };
  const columns = [
    ...sharedColumns,
    {
      title: intl.formatMessage({
        id: `hubs.packages_debrief.columns.reason`
      }),
      dataIndex: 'state',
      width: 190,
      render: (state, record) =>
        exceptionsMapping(record)[state.lastExceptionCode]
    },
    {
      title: (
        <p>
          {intl.formatMessage({
            id: `hubs.packages_debrief.columns.attempts`
          })}
        </p>
      ),
      dataIndex: '',
      width: 65,
      render: renderNoOfAttempts
    },
    {
      title: (
        <p>
          {intl.formatMessage({
            id: `hubs.packages_debrief.columns.order_age`
          })}
        </p>
      ),
      dataIndex: '',
      width: 70,
      render: renderOrderAge
    },
    {
      width: 70,
      render: (_, delivery) => {
        const exceptionState = getExceptionState(delivery);
        return (
          exceptionState?.fullText === REJECTED_RETURN && (
            <BRButton
              label={intl.formatMessage({ id: 'common.upload' })}
              type={'primary'}
              onClick={() =>
                openModal(UploadRejectModal, {
                  confirm: (imagesUrls) => {
                    addImagesToException({
                      delivery,
                      imagesUrls,
                      isUploadFromButton: true
                    });
                  },
                  intl
                })
              }
            />
          )
        );
      }
    }
  ];

  useEffect(() => {
    setExceptions(
      starData?.deliveries.filter((el) =>
        EXCEPTION_ORDERS_CODES.includes(el.state.code)
      )
    );
  }, [starData]);
  const handleRemoveFromRecievedTable = (record) => {
    const { trackingNumber } = record;
    openModal(DeleteModal, {
      onSuccess: () => {
        setScannedExceptionItems((prevState) =>
          prevState.filter((el) => el.trackingNumber !== trackingNumber)
        );
        setExceptions((prev) => {
          const temp = prev.filter(
            (el) => el.trackingNumber !== trackingNumber
          );
          return [...temp, record];
        });
      }
    });
  };

  const handleOpenCreateTicketModal = (record) => {
    openModal(CreateTicketModal, {
      ticketReasonsButtons: [
        'order_lost',
        'order_damage',
        'order_opened_by_force'
      ],
      trackingNumber: record.trackingNumber,
      hubName,
      onSuccess: (ticketId) => {
        notify(`Ticket Id: ${ticketId} is created successfulyy`, 'success');
      }
    });
  };

  const menuItems = (record) => {
    return (
      <Menu onClick={(e) => e.domEvent.stopPropagation()}>
        <Menu.Item onClick={() => handleRemoveFromRecievedTable(record)}>
          {intl.formatMessage({
            id: `hubs.packages_debrief.actions.remove`
          })}
        </Menu.Item>
        <Menu.Item onClick={() => handleOpenCreateTicketModal(record)}>
          {intl.formatMessage({
            id: `hubs.packages_debrief.actions.create_ticket`
          })}
        </Menu.Item>
      </Menu>
    );
  };

  const receivedTableColumns = [
    ...sharedColumns,
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.no_of_packages'
      }),
      dataIndex: 'multiPackages',
      render: (multiPackages, record) =>
        multiPackages ? <MultiPackagesCount delivery={record} /> : 1
    },
    {
      title: intl.formatMessage({
        id: 'hubs.packages_debrief.columns.state'
      }),
      dataIndex: '',
      render: (delivery) => {
        const exceptionState = getExceptionState(delivery);
        if (delivery.actionType) {
          return (
            <Tag color={ACTION_TYPES_TAG_COLOR[delivery.actionType]}>
              {ACTION_TYPES[delivery.actionType]}
            </Tag>
          );
        } else if (exceptionState) {
          return (
            <>
              <p
                className={`br-exceptions-tab__date ${exceptionState.bgColor}`}
              >
                {exceptionState.fullText}
              </p>
            </>
          );
        }
      }
    },

    {
      title: intl.formatMessage({ id: 'pickups.table_columns.action' }),
      width: 60,
      align: 'center',
      render: (_, record) => {
        return (
          <Dropdown
            overlay={() => menuItems(record)}
            placement="bottomRight"
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
            getPopupContainer={(trigger) => trigger.parentElement.parentElement}
          >
            <MoreOutlined />
          </Dropdown>
        );
      }
    }
  ];

  const onChangeDeliveryType = async (value) => {
    if (value) {
      setFilterUsed(true);
      setFilterItems(exceptions.filter((el) => el.type.includes(value)));
    } else {
      setFilterUsed(false);
      setFilterItems(exceptions);
    }
  };

  const getExceptionState = ({
    scheduledAt,
    state,
    type,
    attemptsCount = 0,
    forwardAttemptsCount = 0,
    collectedFromConsignee,
    maximumAllowedAttempts
  }) => {
    const exceptionCode = Number(state?.lastExceptionCode);
    const isRescheduledToTomorrow =
      dayjs(scheduledAt).format('D MMM') === nextWorkingDay;
    const isReturn = isReturnOrder({ type, collectedFromConsignee });
    const isCRPOrCashCollection = [CASH_COLLECTION, CRP].includes(type);
    const isBusinessMaxAttempt = maximumAllowedAttempts <= forwardAttemptsCount;

    if (
      isMaxNumOfAttempts(forwardAttemptsCount).PICKUP || // Order is type pickup and in state exception for the third time
      [
        ...CUSTOMER_REFUSED_RECEIVING,
        ...CANCELED_CUSTOMER_WANTS_TO_OPEN_PACKAGE
      ].includes(exceptionCode) ||
      (!isReturn &&
        !isCRPOrCashCollection &&
        CANCELED_BY_SHIPPER.includes(exceptionCode)) // Received an exception reason “Canceled by the shipper”
    ) {
      return ASSIGN_TO_ON_HOLD;
    } else if (
      isBusinessMaxAttempt ||
      isMaxNumOfAttempts(forwardAttemptsCount).DELIVERY // Received 3 delivery attempts (order state is Exception and this is the third exception)
    ) {
      if (!isCRPOrCashCollection) {
        return ASSIGN_TO_RTO;
      }
    } else if (
      (isReturn && RETURN_REJECTED_EXCEPTION_CODES.includes(exceptionCode)) ||
      isMaxNumOfAttempts(attemptsCount - forwardAttemptsCount).RETURN // Order is type return and in state exception for the third time
    ) {
      return ASSIGN_TO_RETURN_ISSUES;
    } else if (isRescheduledToTomorrow) {
      return RESCHEDULED_TO_TOMORROW;
    } else if (POSTPONED_ORDER.includes(exceptionCode)) {
      return RESCHEDULED_TO_DATE(scheduledAt);
    }

    return null;
  };

  const detectScannerWhilePopupVisible = ({ key, keyCode }) => {
    // The last event keyCode for the scanner is 'Enter' which is 13,
    // so that we save the scanner value
    if (keyCode === 13) {
      setScannedTrackingNumber(trackingNumberCharacters);
      trackingNumberCharacters = '';
    } else {
      trackingNumberCharacters += key;
    }
  };

  const openPopup = (delivery) => {
    const data = getExceptionState(delivery);
    const deliveryAction =
      delivery.actionType && DELIVERY_ACTIONS_MAPPING(delivery);

    if (data || deliveryAction) {
      setPopupData(deliveryAction || data);
      showPopup();
    } else {
      closePopup();
    }
  };

  const getActionType = async (trackingNumber) => {
    try {
      const payload = {
        trackingNumbers: [trackingNumber],
        searchType: DELIVERIES_SCAN_TYPES.RECEIVE_ORDER_SCAN
      };

      const { data } = await scanDeliveries(payload);

      return {
        actionType: getDeliveryPendingActions(data[0]),
        deliveryData: data[0]
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const moveToReceiving = ({
    trackingNumber,
    isTrackingNumberFound,
    actionType,
    imagesUrls,
    packageNumber,
    deliveryData
  }) => {
    const delivery = exceptions[isTrackingNumberFound];
    if (imagesUrls) {
      addImagesToException({ delivery, imagesUrls });
    }

    openPopup({
      ...exceptions[isTrackingNumberFound],
      ...deliveryData,
      actionType
    });
    if (
      isMaxNumOfAttempts(
        exceptions[isTrackingNumberFound]?.forwardAttemptsCount
      ).DELIVERY // Received 3 delivery attempts (order state is Exception and this is the third exception)
    ) {
      SoundsService.longBeep();
    } else {
      SoundsService.beep();
    }
    if (!imagesUrls) {
      setScannedExceptionItems((prev) => [
        {
          ...exceptions[isTrackingNumberFound],
          ...(deliveryData.maximumAllowedAttempts && {
            maximumAllowedAttempts: deliveryData.maximumAllowedAttempts
          }),
          actionType,
          ...(exceptions[isTrackingNumberFound].multiPackages && {
            scannedTimes: 1
          })
        },
        ...prev
      ]);
    }
    setExceptions((prev) => {
      const temp = prev.filter((el) => el.trackingNumber !== trackingNumber);
      return [...temp];
    });

    trackingNumberRef?.current?.focus({
      cursor: 'start'
    });
  };

  const checkTrackingNumberExistance = async ({ value, packageNumber }) => {
    if (!value) {
      return;
    }
    const trackingNumber = value;
    if (trackingNumber.trim().length >= 6) {
      const isTrackingNumberFound = exceptions.findIndex(
        (el) => el.trackingNumber === trackingNumber
      );
      if (isTrackingNumberFound > -1) {
        const { actionType, deliveryData } = await getActionType(
          trackingNumber
        );
        let delivery = exceptions[isTrackingNumberFound];

        const exceptionState = getExceptionState(delivery);
        if (
          exceptionState?.fullText === REJECTED_RETURN &&
          !delivery?.rejectedReturnImages
        ) {
          return openModal(UploadRejectModal, {
            confirm: (imagesUrls) =>
              moveToReceiving({
                trackingNumber,
                isTrackingNumberFound,
                actionType,
                imagesUrls,
                packageNumber,
                deliveryData
              }),
            intl
          });
        } else {
          moveToReceiving({
            trackingNumber,
            isTrackingNumberFound,
            actionType,
            deliveryData
          });
        }
      } else {
        const foundIndex = scannedExceptionItems.findIndex(
          (exceptionItem) => exceptionItem.trackingNumber === value
        );
        if (
          foundIndex > -1 &&
          isInvalidMultiPackageScan({
            delivery: scannedExceptionItems[foundIndex],
            packageNumber
          })
        ) {
          notify(
            `The package with tracking number ${trackingNumber} is not listed!`,
            'error',
            true
          );
        } else {
          const newScannedExceptionItems = [...scannedExceptionItems];
          newScannedExceptionItems[foundIndex].scannedPackages.push(
            packageNumber
          );

          SoundsService.beep();

          setScannedExceptionItems(newScannedExceptionItems);
        }
      }
      setTrackingNumberInputValue('');
      setScannedTrackingNumber('');
    }
  };

  const preSubmissionValidation = () => {
    if (
      scannedExceptionItems.some(
        (exceptionItem) =>
          exceptionItem.multiPackages !== exceptionItem.scannedPackages?.length
      )
    ) {
      return openModal(MissingMultiPackagesModal, {
        deliveries: scannedExceptionItems,
        onConfirm: handleConfirmReceivingOrders
      });
    }

    handleConfirmReceivingOrders();
  };

  const handleConfirmReceivingOrders = async () => {
    try {
      setIsLoading(true);
      await receivePackagesAtHub({
        updateType: 'receiveMany',
        deliveries: scannedExceptionItems
      });
      setScannedExceptionItems([]);
      await getExceptions();
      notify('Deliveries received successfully!', 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const scanTrackingNumber = _debounce((value) => {
    const [trackingNumber, packageNumber] = value.split('/');
    const parsedPackageNumber = parseInt(packageNumber);

    if (
      !isNil(packageNumber) &&
      (!numberRegex.test(parsedPackageNumber) || parsedPackageNumber === 0)
    ) {
      notify(
        intl.formatMessage({
          id: 'hubs.receive_new_pickup.invalid_multipackage'
        })
      );
      return;
    }

    checkTrackingNumberExistance({
      value: trackingNumber,
      packageNumber: parsedPackageNumber
    });
  }, 500);

  const handleTrackingNumberChange = (value) => {
    setTrackingNumberInputValue(value);
    scanTrackingNumber(value);
  };

  useEffect(() => {
    if (handleConfirmReceiving) {
      handleConfirmReceiving[TAB_KEYS.EXCEPTIONS] = preSubmissionValidation;
    }
  });

  useEffect(() => {
    setIsConfirmReceivingDisabled(isLoading || !scannedExceptionItems?.length);
  }, [isLoading, scannedExceptionItems]);

  useEffect(() => {
    if (scannedTrackingNumber) {
      closePopup();
      checkTrackingNumberExistance({ value: scannedTrackingNumber });
    }
  }, [scannedTrackingNumber]);

  return (
    <div className="br-exception-tab">
      <div className="br-form-row br-exception-tab__form">
        <span>
          <p className="br-exception-tab__form-label">
            {intl.formatMessage({
              id: `hubs.packages_debrief.exceptions_tab.tracking_number`
            })}
          </p>
          <Input
            id="trackingNumberInput"
            placeholder="Tracking Number"
            ref={trackingNumberRef}
            value={trackingNumberInputValue}
            onChange={({ target }) => {
              handleTrackingNumberChange(target?.value);
            }}
            allowClear
          />
        </span>
        <span>
          <p className="br-exception-tab__form-label">Select Order Type</p>
          <Select
            name="type"
            options={DELIVERY_TYPES}
            allowClear
            onChange={onChangeDeliveryType}
          >
            {DELIVERY_TYPES.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </span>
      </div>
      <SwappingTables
        title1={`Exceptions (${
          isFilterUsed
            ? filterItems.length
            : starData?.deliveries.filter((el) =>
                EXCEPTION_ORDERS_CODES.includes(el.state.code)
              ).length - scannedExceptionItems?.length
        })`}
        title2={`Received (${scannedExceptionItems.length}/${
          isFilterUsed
            ? filterItems.length
            : starData?.deliveries.filter((el) =>
                EXCEPTION_ORDERS_CODES.includes(el.state.code)
              ).length
        })`}
        columns={columns}
        recievedColumns={receivedTableColumns}
        list={isFilterUsed ? filterItems : exceptions}
        scannedList={scannedExceptionItems}
        buttonText={intl.formatMessage({
          id: `hubs.packages_debrief.actions.confirm_receiving_orders`
        })}
        disableButton={isLoading}
        handleConfirmReceivingOrders={handleConfirmReceivingOrders}
      />
      {isPopupVisible && (
        <BRScanningPopup
          visible={isPopupVisible}
          onCancel={closePopup}
          scannerCallback={detectScannerWhilePopupVisible}
          actionTitle={popupData?.title}
          actionData={popupData?.subtitle}
          bgClassName={popupData?.bgColor}
        />
      )}
    </div>
  );
};

export default injectIntl(withRouter(ExceptionsTab));
