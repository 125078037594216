import { fmt } from 'IntlWrapper/IntlWrapper';
import dayjs from 'dayjs';

export const QUICK_FILTERS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Deactivated', value: 'DEACTIVATED' },
  { label: 'Expired', value: 'EXPIRED' }
];

export const PROMOTIONS_STATES = {
  ACTIVE: 'ACTIVE',
  DEACTIVATED: 'DEACTIVATED',
  EXPIRED: 'EXPIRED'
};

export const PROMOTION_VALUE_TYPES = {
  FIXED: { label: 'Fixed Amount', value: 'FIXED_VALUE' },
  PERCENTAGE: { label: '%Percentage', value: 'PERCENTAGE' }
};

export const FEES_OPTIONS = {
  shippingFee: {
    value: 'shippingFee',
    label: fmt({ id: 'communication.promotion.fee_options.ship' })
  },
  extraCodFee: {
    value: 'extraCodFee',
    label: fmt({ id: 'communication.promotion.fee_options.extra_cod' })
  },
  insuranceFee: {
    value: 'insuranceFee',
    label: fmt({ id: 'communication.promotion.fee_options.insurance' })
  },
  expediteFee: {
    value: 'expediteFee',
    label: fmt({ id: 'communication.promotion.fee_options.expedite' })
  },
  codFee: {
    value: 'codFee',
    label: fmt({ id: 'communication.promotion.fee_options.cod' })
  },
  openingPackageFee: {
    value: 'openingPackageFee',
    label: fmt({ id: 'communication.promotion.fee_options.open_package' })
  }
};

export const REDEEM_OPTIONS = {
  ACTION: {
    value: 'ACTION',
    label: fmt({ id: 'communication.promotion.redeem_options.required' })
  },
  AUTO: {
    value: 'AUTO',
    label: fmt({ id: 'communication.promotion.redeem_options.auto' })
  }
};

export const RULES_OPTIONS = {
  // NUM_OF_ORDERS: {
  //   label: fmt({ id: 'communication.promotion.rules_options.num_orders' }),
  //   value: 'numberOfOrders'
  // },
  // PICKUP_LOCATION: {
  //   label: fmt({ id: 'communication.promotion.rules_options.pickup' }),
  //   value: 'pickupLocation'
  // },
  // CONSIGNEE_LOCATION: {
  //   label: fmt({ id: 'communication.promotion.rules_options.consignee' }),
  //   value: 'consigneeLocation'
  // }, // will be Used in "Promotion-V3"
  NONE: {
    label: fmt({ id: 'communication.promotion.rules_options.none' }),
    value: 'None'
  }
};

export const ELIGABLE_BUSINESSES_RULE = 'BUSINESS_ID_INCLUDED';

export const ELIGABLE_BUSINESSES_SAVE_NAME = 'Eligable_Businesses_list';

export const DATE_FORMAT = 'ddd, DD MMM, YYYY';

export const renderDateColumn = (date) => {
  const splitDate = dayjs(date).format(DATE_FORMAT).split('/');
  return (
    <div className="br-promotion__table-value">
      <span>{splitDate[0]}</span>
      <span> {splitDate[1]}</span>
    </div>
  );
};

export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';
