import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Divider, Button, Dropdown, Menu, Modal } from 'antd';
import Icon from '@ant-design/icons';

import {
  getStarInformation,
  getStarLiabilities,
  excludeStarFromLiability,
  fetchStarWallet,
  resetStarLiability,
  unlinkStarPhoneNumber,
  activateStar,
  deactivateStar,
  getNationalIdViewLink
} from 'services/stars';
import {
  STAR_INFO_CELLS,
  PAGE_LIMIT,
  STAR_DEACTIVATION_REASONS_LABEL_ID,
  STAR_NATIONAL_ID_ATTACHMENTS,
  SALARY_TYPES
} from 'constants/stars';
import { getCurrency } from 'common/countries/countries-mapping';

import { NumberWithCommasSeperator } from 'utils/stars';
import { openModal } from 'utils/modal';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { isUserAuthorized, isDefaultBostaCourier } from 'utils/helpers';
import { ACL_MATRIX } from 'common/permissions';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import CashRecords from 'components/Stars/components/StarDetailsContainer/CashRecords/CashRecords';
import DataLog from 'components/Stars/components/StarDetailsContainer/DataLog/DataLog';
import DateRangeModal from 'components/Stars/components/DateRangeModal/DateRangeModal';
import BRHeader from 'components/BRHeader/BRHeader';
import AddEditBonusesAndDeductions from 'components/Stars/components/BonusesAndDeductions/components/AddEditBonusesAndDeductions/AddEditBonusesAndDeductions';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import BRButton from 'components/BRButton/BRButton';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

import { ReactComponent as editIcon } from 'assets/imgRevamp/edit.svg';
import { ReactComponent as excludeIcon } from 'assets/imgRevamp/exclude.svg';
import { ReactComponent as BonusAndDeduction } from 'assets/imgRevamp/wallet-add.svg';
import { ReactComponent as DropdownIcon } from 'assets/bosta-icons/Dropdown.svg';

import './StarDetails.less';

const StarDetails = ({
  match: {
    params: { id: starId }
  },
  location,
  history,
  intl
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [starDetails, setStarDetails] = useState({});
  const [tablesData, setTablesData] = useState({ data: [], count: 0 });
  const [starWallet, setStarWallet] = useState(0);

  const [selectedSalaryType, setSelectedSalaryType] = useState([]);
  const [salaryConfigration, setSalaryConfigration] = useState(null);

  const getStarNationalImages = async (starId) => {
    try {
      const { results } = await getNationalIdViewLink(starId, {
        attachments: STAR_NATIONAL_ID_ATTACHMENTS
      });
      return results;
    } catch (error) {
      return null;
    }
  };

  const getStarInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const { message: starInfo } = await getStarInformation(starId);
      const images = await getStarNationalImages(starId);
      starInfo.nationalImages = images;
      setStarDetails(starInfo);
      setSalaryConfigration(starInfo?.salaryConfig);
      setSelectedSalaryType(checkSalaryInEdit(starInfo?.salaryConfig));
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  }, [starId]);

  const getStarWallet = useCallback(async () => {
    try {
      setIsLoading(true);
      const starWalletValue = await getStarLiabilities(starId);
      const formattedNumber = NumberWithCommasSeperator(
        starWalletValue?.totalLiability
      );
      setStarWallet(formattedNumber);
    } catch (error) {
      setStarWallet(0);
    }
    setIsLoading(false);
  }, [starId]);

  const getCashRecords = async ({ pageId, dates }) => {
    try {
      const {
        message: { result, count }
      } = await fetchStarWallet(starId, {
        page: pageId - 1,
        limit: PAGE_LIMIT,
        ...(dates && { ...dates })
      });
      setTablesData({ data: result, count });
      return { list: result, total: count };
    } catch (error) {
      notify(error.message);
    }
  };

  const getStarDeactivationReasonOptions = () => {
    return STAR_DEACTIVATION_REASONS_LABEL_ID.map((labelId) => {
      const label = intl.formatMessage({
        id: `stars.star_details.deactivation_reasons.${labelId}`
      });

      return { label, value: label };
    });
  };

  const handleChangeStarStatus = async (deactivationReason) => {
    try {
      const isActivating = !starDetails?.isVerified;

      const payload = {
        verified: isActivating,
        ...(!isActivating && { deactivationReason })
      };

      const serviceFunction = isActivating ? activateStar : deactivateStar;

      await serviceFunction({ starId, payload });

      getStarInfo();
      notify(
        intl.formatMessage({
          id: 'stars.star_details.actions.updated_successfully'
        }),
        'success'
      );
    } catch (err) {
      notify(err.message);
    }
  };
  const handleStarStatus = async () => {
    const isActivating = !starDetails?.isVerified;

    if (isActivating) {
      openModal(ConfirmationModal, {
        title: intl.formatMessage({
          id: 'stars.star_details.actions.activate_star'
        }),
        content: intl.formatMessage({
          id: 'stars.star_details.actions.activate_confirmation'
        }),
        confirmButtonLabel: intl.formatMessage({
          id: 'stars.star_details.actions.activate'
        }),
        confirmButtonType: 'primary',
        modalAction: handleChangeStarStatus
      });
    } else {
      openModal(BRDropdownModal, {
        title: intl.formatMessage({
          id: 'stars.star_details.actions.deactivation_reason_label'
        }),
        confirmBtnText: intl.formatMessage({
          id: 'stars.star_details.actions.deactivate'
        }),
        dropdownData: getStarDeactivationReasonOptions(),
        onConfirm: (value) => handleChangeStarStatus(value)
      });
    }
  };

  const handleOnExcludeSubmitClick = async (data) => {
    setIsLoading(true);
    try {
      const payload = {
        excludeStarData: data
      };
      const result = await excludeStarFromLiability(starId, payload);
      notify(result.message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleExcludeStarFromLiability = () => {
    openModal(DateRangeModal, {
      onSubmit: (payload) => handleOnExcludeSubmitClick(payload)
    });
  };

  const handleResetLiability = async () => {
    setIsLoading(true);
    try {
      const { message } = await resetStarLiability(starId);
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleCreateBonusOrDeduction = () => {
    openModal(AddEditBonusesAndDeductions, {
      values: {
        ...starDetails,
        key: starId,
        profile: {
          firstName: starDetails?.firstName,
          lastName: starDetails?.lastName
        }
      }
    });
  };

  const handleUnLinkStarPhone = async () => {
    setIsLoading(true);
    try {
      await unlinkStarPhoneNumber(starId);
      getStarInfo();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const starMenuActions = (
    <Menu className="br-star-details__actions">
      <Menu.Item
        onClick={() => {
          history.push(`/stars/all/${starId}/edit`);
        }}
        disabled={
          !isUserAuthorized(aclMatrix.STARS_EDIT, [ACL_MATRIX.STARS_UPDATE])
        }
        icon={<Icon component={editIcon} />}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleExcludeStarFromLiability();
        }}
        disabled={
          !isUserAuthorized(aclMatrix.STARS_EXCLUDE_FROM_LIABILITY, [
            ACL_MATRIX.STARS_EXCLUDE_LIABILITY
          ])
        }
        icon={<Icon component={excludeIcon} />}
      >
        Exclude from Liability
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          handleCreateBonusOrDeduction();
        }}
        disabled={
          !isUserAuthorized(aclMatrix.STARS, [
            ACL_MATRIX.STARS_CONFIGURE_SALARY
          ])
        }
        icon={<Icon component={BonusAndDeduction} />}
      >
        Add Bonus or Deduction
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          handleResetLiability();
        }}
        disabled={
          !isUserAuthorized(aclMatrix.STARS_RESET_LIABILITY, [
            ACL_MATRIX.STARS_EXCLUDE_LIABILITY
          ])
        }
        icon={<Icon component={excludeIcon} />}
      >
        {intl.formatMessage({
          id: `stars.star_details.reset_liability`
        })}
      </Menu.Item>
      <Menu.Item
        onClick={handleUnLinkStarPhone}
        disabled={
          !isUserAuthorized(aclMatrix.STARS_UNLINK_PHONE_NUMBER, [
            ACL_MATRIX.STARS_UNLINK_PHONE
          ])
        }
        icon={<Icon component={excludeIcon} />}
      >
        {intl.formatMessage({
          id: `stars.star_details.unlink_phone`
        })}
      </Menu.Item>
    </Menu>
  );

  const deactivateStarModal = ({ close, onSuccess, ...props }) => {
    const handleConfirm = () => {
      onSuccess();
      close();
    };

    return (
      <Modal
        {...props}
        title={null}
        footer={null}
        onCancel={close}
        className="br-request-pickup-modal"
      >
        <div className="br-request-pickup-modal__title">
          {starDetails?.isVerified ? 'Deactivate Star' : 'Activate Star'}
        </div>
        Are you sure you want to
        {starDetails?.isVerified ? ' deactivate' : ' activate'} this star?
        <div className="br-request-pickup-modal__footer">
          <Button onClick={close}>
            {intl.formatMessage({
              id: 'common.cancel'
            })}
          </Button>
          <Button type="primary" onClick={handleConfirm}>
            Ok
          </Button>
        </div>
      </Modal>
    );
  };

  const checkSalaryInEdit = (salaryConfig) => {
    const newArray = [];
    Object.keys(salaryConfig).map((salary) => {
      SALARY_TYPES.forEach((type) => {
        if (type.salaryConfigKey === salary) {
          newArray.push(type);
        }
      });
    });
    return newArray;
  };

  useEffect(() => {
    getStarWallet();
    getStarInfo();
    getCashRecords({ pageId: 1 });
  }, [starId]);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={
          <BRHeader
            title="Stars"
            showBackArrow
            handleGoBackUrl={() => history.push(`/stars`)}
          />
        }
        className="br-star-details"
        content={
          <>
            <div className="br-star-details__header">
              <span className="display-flex">
                <div className="br-star-details__star-name">
                  <span className="br-star-details__label">
                    {intl.formatMessage({
                      id: `stars.star_details.star_name`
                    })}
                  </span>
                  <span className="br-star-details__value">
                    {`${starDetails?.firstName || ''} ${
                      starDetails?.lastName || ''
                    }`}
                  </span>
                </div>
                <Divider type="vertical" className="br-star-details__divider" />
                <div className="br-star-details__star-wallet">
                  <span className="br-star-details__label">
                    {intl.formatMessage({
                      id: `stars.star_details.star_Wallet`
                    })}
                  </span>
                  <span className="br-star-details__wallet_value">
                    {intl.formatMessage(
                      {
                        id: COUNTRY_CURRENCY
                      },
                      { cod: starWallet }
                    )}
                  </span>
                </div>
              </span>
              {isDefaultBostaCourier([
                ...aclMatrix.THREE_PL,
                VENDOR_MANAGER
              ]) && (
                <div className="br-star-details__header-actions">
                  <BRButton
                    type={
                      starDetails?.isVerified
                        ? 'destructive-primary'
                        : 'primary'
                    }
                    onClick={handleStarStatus}
                    label={intl.formatMessage({
                      id: `stars.star_details.actions.${
                        starDetails?.isVerified ? 'deactivate' : 'activate'
                      }`
                    })}
                    disabled={
                      !isUserAuthorized(aclMatrix.STARS_EDIT, [
                        starDetails?.isVerified
                          ? ACL_MATRIX.STARS_DEACTIVATE
                          : ACL_MATRIX.STARS_ACTIVATE
                      ])
                    }
                  />

                  <Dropdown overlay={starMenuActions} placement="bottomRight">
                    <BRButton
                      label={intl.formatMessage({
                        id: `stars.star_details.actions.title`
                      })}
                      suffixIcon={<DropdownIcon />}
                    />
                  </Dropdown>
                </div>
              )}
            </div>
            <BRInformationTable
              dataSource={{ starId, ...starDetails }}
              cells={STAR_INFO_CELLS}
              totalCol={5}
            />
            {!!selectedSalaryType.length && (
              <div className="br-create-edit-star__salary-section">
                <p className="heading">
                  {intl.formatMessage({
                    id: `stars.star_details.salary_config`
                  })}
                </p>
                {selectedSalaryType.map((type) => (
                  <div
                    key={type.value}
                    className="display-flex align-center justify-content-between"
                  >
                    <span className="br-create-edit-star__salary-section__right-content">
                      <span className="br-create-edit-star__salary-section__title">
                        {type.value}
                      </span>
                      <span className="br-create-edit-star__salary-section__subtitle">
                        {salaryConfigration ? (
                          <>
                            {intl.formatMessage(
                              {
                                id: `stars.create_edit_star.salary_type.${type.helpSentenceFor}.help_sentense`
                              },
                              {
                                currency: getCurrency().localized,
                                amount:
                                  salaryConfigration[type.salaryConfigKey],
                                vehicleType: (
                                  <span className="bold">
                                    {salaryConfigration.vehicleType ||
                                      starDetails?.currentVehicle}
                                  </span>
                                ),
                                zone: salaryConfigration.salaryInfo?.zoneName
                              }
                            )}
                          </>
                        ) : (
                          <>
                            {intl.formatMessage({
                              id: `stars.create_edit_star.salary_type.${type.helpSentenceFor}.title`
                            })}
                            {intl.formatMessage({
                              id: `stars.create_edit_star.salary_type.default_help_sentense`
                            })}
                          </>
                        )}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="br-star-details__tables">
              <span className="br-star-details__tables__headers">
                <Button
                  type={!location.pathname.includes('data-log') && 'secondry'}
                  className={classnames({
                    'br-star-details__default-btn-tab':
                      location.pathname.includes('data-log')
                  })}
                  onClick={() => {
                    history.push(`/stars/all/${starId}/cash-records`);
                  }}
                >
                  {intl.formatMessage({
                    id: `stars.star_details.cash_records`
                  })}
                </Button>
                {!isUserAuthorized('HUB_CASHIER') && (
                  <Button
                    type={location.pathname.includes('data-log') && 'secondry'}
                    className={classnames({
                      'br-star-details__default-btn-tab':
                        !location.pathname.includes('data-log')
                    })}
                    onClick={() => {
                      history.push(`/stars/all/${starId}/data-log`);
                    }}
                  >
                    {intl.formatMessage({
                      id: `stars.star_details.data_log`
                    })}
                  </Button>
                )}
              </span>
              <Divider className="br-star-details__tables__divider" />
            </div>
            <Switch>
              <Route
                path={['/stars/all/:id', '/stars/all/:id/cash-records']}
                exact
                render={() => (
                  <CashRecords
                    listData={tablesData?.data}
                    listFunction={getCashRecords}
                    getStarWallet={getStarWallet}
                    count={tablesData.count}
                  />
                )}
              />
              <Route
                path={['/stars/all/:id/data-log']}
                exact
                render={() => <DataLog starDetails={starDetails} />}
              />
            </Switch>
          </>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(StarDetails));
