import { Switch, Route } from 'react-router-dom';
import { withRouter, Redirect } from 'react-router';

import {
  FULFILLMENT_PRODUCTS_OMNIFUL_MAIN_PATH,
  FULFILLMENT_OMNIFUL_PRODUCTS_ROUTES
} from 'constants/fulfillment';

const FulfillmentProductsOmniful = () => {
  return (
    <Switch>
      {FULFILLMENT_OMNIFUL_PRODUCTS_ROUTES.map((routeProps, index) => (
        <Route key={index} {...routeProps} />
      ))}
      <Redirect to={FULFILLMENT_PRODUCTS_OMNIFUL_MAIN_PATH} />
    </Switch>
  );
};

export default withRouter(FulfillmentProductsOmniful);
