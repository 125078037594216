import React, { useEffect, useState, useRef } from 'react';
import { Input, Button, Form, Row, Col, DatePicker, Select, Image } from 'antd';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

import { downloadAsXlsx } from 'utils';
import { FORMAT_DATE_TO_UTC } from 'utils/helpers';
import {
  getShippingOrders,
  exportItems
} from 'services/international-shipping-in';

import { notify } from 'components/Notify/Notify';
import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';

// import './InternationalItems.less';

const InternationalItems = ({ intl, businesses, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disableExport, setDisableExport] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [count, setCount] = useState(0);

  const refreshUpcomingOrders = useRef();

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setDisableExport(!selectedRows?.length);
  }, [selectedRows]);

  useEffect(() => {
    if (businesses?.length) {
      setBusiness(businesses);
    }
  }, [businesses]);

  const handleOnExportClick = async () => {
    setDisableExport(true);
    setIsLoading(true);
    try {
      const ids = selectedRows?.toString();
      const result = await exportItems(ids);
      downloadAsXlsx(result, `Orders-Info.xlsx`);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
    setDisableExport(false);
  };

  const getItems = async ({ pageId: pageNumber, pageLimit: pageSize }) => {
    setIsLoading(true);
    try {
      const { data } = await getShippingOrders({
        pageNumber: pageNumber - 1,
        pageSize,
        searchQuery
      });

      setCount(data.total || 0);
      setIsLoading(false);
      return {
        list: formatItems(data.items),
        total: data.total || 0
      };
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const handleOnFinish = async (values) => {
    if (values.status && values.status.length) {
      values.status = values.status.toString();
    } else {
      values.status = undefined;
    }
    if (values.createdAt?.length) {
      values.createdAtMin = FORMAT_DATE_TO_UTC(values.createdAt[0]);
      values.createdAtMax = FORMAT_DATE_TO_UTC(values.createdAt[1]);
      delete values.createdAt;
    }
    if (values.updatedAt?.length) {
      values.updatedAtMin = FORMAT_DATE_TO_UTC(values.updatedAt[0]);
      values.updatedAtMax = FORMAT_DATE_TO_UTC(values.updatedAt[1]);
      delete values.updatedAt;
    }
    if (values.deliveredAt?.length) {
      values.deliveredAtMin = FORMAT_DATE_TO_UTC(values.deliveredAt[0]);
      values.deliveredAtMax = FORMAT_DATE_TO_UTC(values.deliveredAt[1]);
      delete values.deliveredAt;
    }
    let searchQuery = '&';
    for (const key in values) {
      if (values[key]) {
        searchQuery += `${key}=${values[key]}&`;
      }
    }
    if (searchQuery !== '&') {
      searchQuery = searchQuery.slice(0, searchQuery.length - 1);
      await setSearchQuery(searchQuery);
      refreshOrdersTable();
    } else {
      await setSearchQuery('');
      refreshOrdersTable();
    }
  };

  const formatItems = (items) => {
    const formattedItems = [];
    items.map((item) => {
      formattedItems.push({
        key: item._id,
        _id: item._id,
        product_image: item.extra.photo,
        item_id: item.providerItemId,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        order_id: item?.paymentOrder?.providerOrderId,
        provider_order_id: item?.providerOrder?.id,
        real_order_id: item?.paymentOrder?.id,
        package_id: item.providerPackageId,
        category: item.extra.categoryName,
        weight: item.extra.weight,
        price: item.providerPrice,
        status: item.status,
        business: {
          name: item.businessInfo.name,
          phone: item.businessInfo.phone
        },
        businessId: item.businessInfo._id,
        trackingNumber: item.lastMileTrackingNumber || 'N/A',
        trackingUrl: item.lastMileShipmentTrackingUrl
      });
    });
    return formattedItems;
  };

  const copyPackageId = (packageId) => {
    navigator.clipboard.writeText(packageId);
    notify('Package Id is copied successfully', 'success');
  };

  const STATUS_MAPPING = {
    arrived_at_us_office: 'Arrived at USA office',
    arrived_at_cn_office: 'Arrived at China office',
    arrived_at_uk_office: 'Arrived at UK office',
    arrived_at_ae_office: 'Arrived at AE office',
    in_transit_to_cairo: 'In transit to Egypt',
    arrived_at_cairo_office: 'Arrived to Egypt',
    in_progress: 'In Progress',
    out_for_delivery: 'Out for Delivery',
    delivered: 'Delivered'
  };

  const columns = () => {
    return [
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.business'
        }),
        dataIndex: 'business',
        render: (business, item) => {
          return (
            <>
              <p> {business.name || 'N/A'}</p>
              <p> {business.phone || 'N/A'}</p>
            </>
          );
        }
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.product_image'
        }),
        dataIndex: 'product_image',
        render: (imageLink) => <Image src={imageLink} alt="" width={50} />,
        className: 'br-international__table-column-image'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.item_id'
        }),
        dataIndex: 'item_id',
        render: (itemProviderId, item) =>
          itemProviderId ? (
            <a
              href={`box/item/${item._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {itemProviderId}
            </a>
          ) : (
            'N/A'
          )
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.order_id'
        }),
        dataIndex: 'order_id',
        render: (orderId, item) =>
          orderId ? (
            <a href={`/box/order/${item?.real_order_id}`} target="_blank">
              {orderId}
            </a>
          ) : (
            'N/A'
          )
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.provider_order_id'
        }),
        dataIndex: 'provider_order_id',
        render: (id) => (id ? id : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.package_id'
        }),
        dataIndex: 'package_id',
        render: (packageId) => (
          <span
            className="br-international-shipping__package-id"
            onClick={() => copyPackageId(packageId)}
          >
            {packageId.slice(0, 6)} ...
          </span>
        )
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.status'
        }),
        dataIndex: 'status',
        render: (status) => (status ? STATUS_MAPPING[status] : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.tracking_number'
        }),
        dataIndex: 'trackingNumber'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.tracking_url'
        }),
        dataIndex: 'trackingUrl',
        render: (trackingUrl) =>
          trackingUrl ? (
            <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
              Click Here
            </a>
          ) : (
            'N/A'
          )
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.category'
        }),
        dataIndex: 'category',
        render: (category) => (category ? category : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.weight'
        }),
        dataIndex: 'weight',
        render: (weight) => (weight ? `${weight}KG` : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.items.table_columns.price'
        }),
        dataIndex: 'price',
        render: (price) => (price ? `${price} EGP` : 'N/A')
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.created_at'
        }),
        dataIndex: 'createdAt',
        render: (date) =>
          date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
      },
      {
        title: intl.formatMessage({
          id: 'international_shipping.orders.table_columns.updated_at'
        }),
        dataIndex: 'updatedAt',
        render: (date) =>
          date ? dayjs(date).tz().format('YYYY-MM-DD / h:mm a') : 'N/A'
      }
    ];
  };

  const STATUS_OPTIONS = [
    {
      label: 'Arrived at International Warehouse',
      value:
        'arrived_at_us_office,arrived_at_cn_office,arrived_at_uk_office,arrived_at_ae_office'
    },
    { label: 'In transit to Egypt', value: 'in_transit_to_cairo' },
    { label: 'Arrived to Egypt', value: 'arrived_at_cairo_office' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Out for Delivery', value: 'out_for_delivery' },
    { label: 'Delivered', value: 'delivered' }
  ];

  const acceptMethods = (refreshMethod) => {
    refreshUpcomingOrders.current = refreshMethod;
  };

  const refreshOrdersTable = () => {
    refreshUpcomingOrders.current({ pageId: 1 });
  };
  return (
    <div>
      <Form
        scrollToFirstError
        name="InternationalItemsForm"
        onFinish={handleOnFinish}
        className="br-international-orders__form-container"
      >
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="providerItemId" label="Item ID">
              <Input placeholder="enter item id" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="providerPackageId" label="Package ID">
              <Input placeholder="enter package id" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="status" label="Status">
              <Select
                mode="multiple"
                placeholder="Select status"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                allowClear
                filterOption={(input, option) =>
                  option.children.props.children[0].props.textToHighlight
                    .toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {STATUS_OPTIONS.map((state) => (
                  <Option key={state.value} value={state.value}>
                    {state.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="businessId" label="Business">
              <Select
                placeholder="Select Business"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {business.map((b) => (
                  <Option key={b._id} value={b._id}>
                    {b.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="priceMin" label="Price Min Range">
              <Input type="number" min={1} placeholder="enter minimum price" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="priceMax" label="Price Max Range">
              <Input type="number" min={1} placeholder="enter maximum price" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="lastMileTrackingNumber" label="Tracking Number">
              <Input placeholder="enter item tracking number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="paymentOrderId" label="Order ID">
              <Input placeholder="enter order id" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="createdAt" label="Created At ">
              <RangePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item name="updatedAt" label="Updated At ">
              <RangePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="deliveredAt" label="Delivered At ">
              <RangePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="br-internationa-orders__form-actions">
        <Button
          type="primary"
          htmlType="submit"
          form="InternationalItemsForm"
          disabled={isLoading}
        >
          Search
        </Button>
        <Button
          type="primary"
          disabled={disableExport}
          onClick={handleOnExportClick}
        >
          Export
        </Button>
      </div>

      <div className="mb-4">
        <b>Total items:</b>
        <span className="ml-2">{count}</span>
      </div>

      <BRSearchableTable
        columns={columns()}
        onRowClick={() => {}}
        showPagination
        pageLimit={50}
        listFunction={getItems}
        shareMethods={acceptMethods}
        selectedRows={setSelectedRows}
      />
    </div>
  );
};

export default injectIntl(withRouter(InternationalItems));
