import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { updateFulfillmentActivation } from 'services/business';
import { FULFILLMENT_SERVICE_OPTIONS } from 'constants/fulfillment';

import { notify } from 'components/Notify/Notify';

const FulfillmentServiceSelector = ({
  businessId,
  businessData,
  onSuccess
}) => {
  const [selectedFulfillmentService, setSelectedFulfillmentService] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const getFulfillmentService = ({
    isBostaFulfillment = false,
    isOmnifulFulfillment = false
  }) =>
    isBostaFulfillment
      ? 'isBostaFulfillment'
      : isOmnifulFulfillment
      ? 'isOmnifulFulfillment'
      : null;

  const updateFulfillmentService = async (flagKey) => {
    setIsLoading(true);
    const payload = {};
    let successMsgId = '';
    if (flagKey) {
      payload[flagKey] = true;
      successMsgId = `fulfillment.fulfillment_success_activation.${
        flagKey === 'isBostaFulfillment' ? 'bosta' : 'omniful'
      }`;
    } else {
      payload[getFulfillmentService(businessData)] = false;
      successMsgId = 'fulfillment.fulfillment_success_deactivation';
    }
    try {
      await updateFulfillmentActivation(businessId, payload);
      notify(
        intl.formatMessage({
          id: successMsgId
        }),
        'success'
      );
      onSuccess?.();
    } catch (error) {
      notify(error.message);
      selectedFulfillmentService(getFulfillmentService(businessData));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (businessData) {
      setSelectedFulfillmentService(getFulfillmentService(businessData));
    }
  }, [businessData]);

  return (
    <Select
      className="br-insurance-plan__selector"
      options={FULFILLMENT_SERVICE_OPTIONS}
      disabled={isLoading}
      onChange={updateFulfillmentService}
      value={selectedFulfillmentService}
    />
  );
};

export default FulfillmentServiceSelector;
