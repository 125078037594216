import http from 'utils/http.js';
import { getAWBLanguage } from 'common/countries/countries-mapping';
import { downloadHeader } from 'utils/download';
import { ACTION_TYPES } from 'constants/order-logs';

export const getOrderDetails = (id) => {
  return http.get(`/admin/deliveries`, { ids: id });
};

export const deleteShipment = (id) => {
  return http.delete(`/deliveries/${id}`);
};

export const printAirwaybill = (
  id,
  {
    isTrackingNumbers = false,
    isBarcode = false,
    isPrintAndRepack = false,
    payload
  } = {}
) => {
  const formattedPayload = {
    lang: getAWBLanguage(),
    ...payload,
    ...(isBarcode && { isBarcode }),
    ...(isTrackingNumbers ? { trackingNumbers: id } : { ids: id }),
    ...(isPrintAndRepack && { actionSource: ACTION_TYPES.REPACKING })
  };
  return http.get(`/admin/deliveries/printawb/`, formattedPayload);
};

export const createOrder = (payload) => {
  return http.post(`/deliveries`, payload);
};

export const editOrder = ({ id, updateData, isNotes = false, updateType }) => {
  const payload = isNotes
    ? {
        updateType: 'addCustomerNote',
        customerNote: updateData
      }
    : {
        updateType: updateType || 'update',
        updatedParams: {
          ...updateData
        }
      };
  return http.put(`/admin/deliveries/${id}`, payload);
};

export const getShipmentsList = () => {
  return http.get(`/deliveries/search`);
};

export const updateOrder = ({ id, payload }) => {
  return http.put(`/admin/deliveries/${id}`, payload);
};

export const createMassOrders = (payload) => {
  return http.post(`/deliveries/CreateByCsv`, payload);
};

export const printMassAirwayBills = (payload) => {
  const data = {
    lang: getAWBLanguage(),
    ...payload
  };
  return http.get(`/deliveries/mass-awb`, data);
};

export const takePendingActions = (id, payload) => {
  return http.put(`/deliveries/${id}/take-pending-actions`, payload);
};

export const reschduleDeliveryDate = (id, payload) => {
  return http.put(`/admin/deliveries/${id}`, payload);
};

export const getProofOfReturn = (id) => {
  return http.get(`/admin/deliveries/proof-of-return?deliveryId=${id}`);
};

export const getImageURL = (imageType, imageName) => {
  return http.get(
    `/admin/deliveries/fetchImageUrl?imageType=${imageType}&imageName=${imageName}`
  );
};

export const getOrderCallLogs = (payload) => {
  return http.post(`/users/stars/star-actions`, payload);
};

export const cancelOrder = ({ deliveryId, payload }) => {
  return http.put(`/admin/deliveries/${deliveryId}`, payload);
};

export const getTicketLogs = (deliveryId, payload) => {
  return http.get(`/tickets/${deliveryId}/tickets`, payload);
};

export const getDeliveryEvents = (payload) => {
  return http.get('/deliveryEvents', payload);
};

export const getTransitLimitExceededDeliveries = (payload) => {
  return http.get('/admin/deliveries/transit-limit-exceeded', payload);
};

export const exportTransitLimitExceededDeliveries = (payload) => {
  return http.get(
    '/admin/deliveries/export-transit-limit-exceeded',
    payload,
    downloadHeader
  );
};

export const getProofUploadLink = (id) => {
  return http.get(
    `/admin/deliveries/${id}/signedAttachmentUrl?type=deliveryProofOfCost`
  );
};

export const getWhatsAppConversation = (trackingNumber) => {
  return http.get(
    `/whatsAppNativeBot/conversation/last-mile/${trackingNumber}`
  );
};
